import React, { createContext, useState, useContext, useEffect } from 'react';
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut, 
  GoogleAuthProvider, 
  getAuth,
  signInWithRedirect,
  getRedirectResult
} from 'firebase/auth';
import { auth } from '../firebase';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Handle redirect result when component mounts
  useEffect(() => {
    const handleRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          // User successfully signed in
          setCurrentUser(result.user);
        }
      } catch (error) {
        console.error('Error handling redirect result:', error);
        if (error.code === 'auth/account-exists-with-different-credential') {
          // Handle linking accounts if needed
          setError('This email is already associated with a different sign-in method');
        }
      }
    };

    handleRedirectResult();
  }, []);

  // Regular auth state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  async function loginWithGoogle() {
    const provider = new GoogleAuthProvider();
    
    // Add scopes if needed
    // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    
    provider.setCustomParameters({
      prompt: 'select_account',
      // Make sure to handle the login flow in the same window
      display: 'page'
    });

    try {
      await signInWithRedirect(auth, provider);
      // The redirect will happen here, and the result will be handled in the useEffect above
    } catch (error) {
      console.error('Google sign-in error:', error);
      throw error;
    }
  }

  const value = {
    auth,
    currentUser,
    signup,
    login,
    logout,
    loginWithGoogle
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
} 