import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useLanguage } from '../contexts/LanguageContext';

const WinePreferences = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [preferences, setPreferences] = useState({
    red: { descriptors: [], regions: [], varietals: [] },
    white: { descriptors: [], regions: [], varietals: [] },
    sparkling: { descriptors: [], regions: [], varietals: [] },
    orange: { descriptors: [], regions: [], varietals: [] },
    rose: { descriptors: [], regions: [], varietals: [] }
  });
  const { t } = useLanguage();

  useEffect(() => {
    const analyzeSavedWines = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const savedWinesRef = collection(db, 'users', currentUser.uid, 'savedWines');
        const querySnapshot = await getDocs(savedWinesRef);
        
        const winesByType = {
          red: [],
          white: [],
          sparkling: [],
          orange: [],
          rose: []
        };

        // Group wines by type
        querySnapshot.forEach(doc => {
          const wine = doc.data();
          const type = wine.type?.toLowerCase() || 'other';
          if (winesByType[type]) {
            winesByType[type].push(wine);
          }
        });

        // Analyze preferences for each type
        const analyzedPreferences = {};
        Object.entries(winesByType).forEach(([type, wines]) => {
          if (wines.length === 0) return;

          const descriptorCount = {};
          const regionCount = {};
          const varietalCount = {};

          wines.forEach(wine => {
            // Count descriptors
            wine.description?.split(',').forEach(desc => {
              const descriptor = desc.trim().toLowerCase();
              descriptorCount[descriptor] = (descriptorCount[descriptor] || 0) + 1;
            });

            // Count regions
            if (wine.region) {
              const region = wine.region.trim();
              regionCount[region] = (regionCount[region] || 0) + 1;
            }

            // Count varietals
            if (wine.varietal) {
              const varietal = wine.varietal.trim();
              varietalCount[varietal] = (varietalCount[varietal] || 0) + 1;
            }
          });

          analyzedPreferences[type] = {
            descriptors: getTopItems(descriptorCount, 5),
            regions: getTopItems(regionCount, 3),
            varietals: getTopItems(varietalCount, 3)
          };
        });

        setPreferences(analyzedPreferences);
      } catch (error) {
        console.error('Error analyzing wine preferences:', error);
      } finally {
        setLoading(false);
      }
    };

    analyzeSavedWines();
  }, [currentUser]);

  const getTopItems = (countObj, limit) => {
    return Object.entries(countObj)
      .sort(([,a], [,b]) => b - a)
      .slice(0, limit)
      .map(([item]) => item);
  };

  if (loading) {
    return (
      <div className="p-4 text-center">
        <div className="animate-pulse">{t('loading')}</div>
      </div>
    );
  }

  if (!currentUser) {
    return (
      <div className="p-4 text-center text-gray-600">
        {t('pleaseLoginForPreferences')}
      </div>
    );
  }

  // Check if there are any preferences
  const hasPreferences = Object.values(preferences).some(
    pref => pref.descriptors.length > 0 || pref.regions.length > 0 || pref.varietals.length > 0
  );

  if (!hasPreferences) {
    return (
      <div className="p-4 text-center text-gray-600">
        {t('noPreferencesSet')}
      </div>
    );
  }

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">
        {t('preferences')}
      </h2>
      <p className="text-gray-600 mb-8">
        {t('showToSommelier')}
      </p>

      <div className="space-y-8">
        {Object.entries(preferences).map(([type, pref]) => {
          if (pref.descriptors.length === 0) return null;

          const typeColors = {
            red: 'border-red-200 bg-red-50',
            white: 'border-yellow-200 bg-yellow-50',
            sparkling: 'border-purple-200 bg-purple-50',
            orange: 'border-orange-200 bg-orange-50',
            rose: 'border-pink-200 bg-pink-50'
          };

          return (
            <div 
              key={type}
              className={`p-6 rounded-lg border ${typeColors[type]} shadow-sm`}
            >
              <h3 className="text-xl font-semibold capitalize mb-4">
                {t(`${type}WinePreferences`)}
              </h3>
              
              <div className="space-y-4">
                {pref.descriptors.length > 0 && (
                  <div>
                    <h4 className="font-medium text-gray-700 mb-2">
                      {t('preferredCharacteristics')}:
                    </h4>
                    <div className="flex flex-wrap gap-2">
                      {pref.descriptors.map(desc => (
                        <span 
                          key={desc}
                          className="px-3 py-1 bg-white rounded-full text-sm border"
                        >
                          {desc}
                        </span>
                      ))}
                    </div>
                  </div>
                )}

                {pref.regions.length > 0 && (
                  <div>
                    <h4 className="font-medium text-gray-700 mb-2">
                      {t('favoriteRegions')}:
                    </h4>
                    <div className="flex flex-wrap gap-2">
                      {pref.regions.map(region => (
                        <span 
                          key={region}
                          className="px-3 py-1 bg-white rounded-full text-sm border"
                        >
                          {region}
                        </span>
                      ))}
                    </div>
                  </div>
                )}

                {pref.varietals.length > 0 && (
                  <div>
                    <h4 className="font-medium text-gray-700 mb-2">
                      {t('preferredVarietals')}:
                    </h4>
                    <div className="flex flex-wrap gap-2">
                      {pref.varietals.map(varietal => (
                        <span 
                          key={varietal}
                          className="px-3 py-1 bg-white rounded-full text-sm border"
                        >
                          {varietal}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WinePreferences; 