import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useLanguage } from '../contexts/LanguageContext';
import LanguageSelector from './LanguageSelector';
import MobileMenu from './MobileMenu';
import Login from './Login';

function Header({ onNavigate, currentPage }) {
  const { currentUser, logout } = useAuth();
  const { t } = useLanguage();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleLoginClick = () => {
    setShowLoginModal(true);
  };

  return (
    <header className="bg-gradient-to-r from-purple-900 to-purple-600 text-white shadow-lg">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Left side - Logo and Navigation */}
          <div className="flex items-center space-x-4">
            <button
              onClick={() => onNavigate('home')}
              className="text-xl font-bold hover:text-gray-200 transition-colors"
            >
              Glugou
            </button>
            {/* Desktop Navigation */}
            <nav className="hidden md:flex space-x-4">
              <button
                onClick={() => onNavigate('home')}
                className={`px-3 py-2 rounded-md text-sm font-medium ${
                  currentPage === 'home' ? 'bg-purple-800' : 'hover:bg-purple-700'
                }`}
              >
                {t('home')}
              </button>
              <button
                onClick={() => onNavigate('favorites')}
                className={`px-3 py-2 rounded-md text-sm font-medium ${
                  currentPage === 'favorites' ? 'bg-purple-800' : 'hover:bg-purple-700'
                }`}
              >
                {t('favorites')}
              </button>
              <button
                onClick={() => onNavigate('preferences')}
                className={`px-3 py-2 rounded-md text-sm font-medium ${
                  currentPage === 'preferences' ? 'bg-purple-800' : 'hover:bg-purple-700'
                }`}
              >
                {t('preferences')}
              </button>
              <button
                onClick={() => onNavigate('menuHistory')}
                className={`px-3 py-2 rounded-md text-sm font-medium ${
                  currentPage === 'menuHistory' ? 'bg-purple-800' : 'hover:bg-purple-700'
                }`}
              >
                {t('menuHistory')}
              </button>
            </nav>
          </div>

          {/* Right side - Language Selector, User Info, and Login/Logout */}
          <div className="flex items-center space-x-4">
            <div className="hidden md:block">
              <LanguageSelector />
            </div>
            
            {currentUser ? (
              <>
                <span className="text-sm hidden md:inline">{currentUser.email}</span>
                <button
                  onClick={logout}
                  className="bg-red-600 hover:bg-red-700 px-3 py-2 rounded-md text-sm font-medium"
                >
                  {t('logout')}
                </button>
              </>
            ) : (
              <button
                onClick={handleLoginClick}
                className="bg-purple-600 hover:bg-purple-700 px-4 py-2 rounded-lg text-white transition-colors"
              >
                {t('loginSignUp')}
              </button>
            )}
            
            {/* Mobile menu button */}
            <button
              onClick={() => setIsMobileMenuOpen(true)}
              className="md:hidden p-2"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <MobileMenu
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        onNavigate={(page) => {
          onNavigate(page);
          setIsMobileMenuOpen(false);
        }}
        currentPage={currentPage}
      />

      {/* Login Modal */}
      {showLoginModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <Login onClose={() => setShowLoginModal(false)} />
        </div>
      )}
    </header>
  );
}

export default Header;