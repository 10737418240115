import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useLanguage } from '../contexts/LanguageContext';
import { loadGoogleMapsApi } from '../utils/googleMaps';

const SaveMenuModal = ({ wines, onClose }) => {
  const { t } = useLanguage();
  const [restaurantName, setRestaurantName] = useState('');
  const [customName, setCustomName] = useState('');
  const [location, setLocation] = useState('');
  const [saving, setSaving] = useState(false);
  const { currentUser } = useAuth();
  const [placesLoaded, setPlacesLoaded] = useState(false);

  useEffect(() => {
    const initializePlaces = async () => {
      try {
        await loadGoogleMapsApi();
        const input = document.getElementById('restaurant-search');
        if (!input) return;

        const autocomplete = new window.google.maps.places.Autocomplete(input, {
          types: ['restaurant'],
          fields: ['name', 'formatted_address', 'place_id']
        });

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place.name) {
            setRestaurantName(place.name);
            setLocation(place.formatted_address || '');
          }
        });

        setPlacesLoaded(true);
      } catch (error) {
        console.error('Error loading Google Maps:', error);
      }
    };

    initializePlaces();
  }, []);

  const handleSave = async () => {
    if (!currentUser || !restaurantName) return;

    try {
      setSaving(true);
      const menuId = Date.now().toString();
      const menuRef = doc(db, 'users', currentUser.uid, 'scannedMenus', menuId);
      
      await setDoc(menuRef, {
        restaurantName,
        customName: customName.trim() || restaurantName,
        location,
        wines,
        scannedAt: new Date().toISOString(),
        menuId
      });

      onClose();
    } catch (error) {
      console.error('Error saving menu:', error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <h2 className="text-xl font-bold mb-4">{t('saveMenu')}</h2>
        
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('selectRestaurant')}
            </label>
            <input
              id="restaurant-search"
              type="text"
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-purple-500"
              placeholder={t('searchRestaurant')}
            />
          </div>

          {restaurantName && (
            <>
              <div className="bg-gray-50 p-3 rounded-lg">
                <div className="font-medium">{restaurantName}</div>
                {location && (
                  <div className="text-sm text-gray-600 mt-1">{location}</div>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('customName')}
                </label>
                <input
                  type="text"
                  value={customName}
                  onChange={(e) => setCustomName(e.target.value)}
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-purple-500"
                  placeholder={t('customNamePlaceholder')}
                />
              </div>
            </>
          )}

          <div className="text-sm text-gray-500">
            {Object.keys(wines).length} {t('winesInMenu')}
          </div>
        </div>

        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            {t('cancel')}
          </button>
          <button
            onClick={handleSave}
            disabled={saving || !restaurantName}
            className={`px-4 py-2 bg-purple-600 text-white rounded-lg
              ${(saving || !restaurantName) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-700'}
              transition-colors`}
          >
            {saving ? t('saving') : t('save')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SaveMenuModal; 