import React, { useEffect, useState } from 'react';
import { collection, query, where, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';

const WineNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) return;

    // Get user's wine preferences
    const fetchPreferences = async () => {
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(userRef);
        
        if (docSnap.exists()) {
          const preferences = docSnap.data().preferences || {};
          
          // Only create query if we have a favorite type
          if (preferences.favoriteType) {
            // Subscribe to new wines matching preferences
            const q = query(
              collection(db, 'wines'),
              where('type', '==', preferences.favoriteType)
            );

            const unsubscribe = onSnapshot(q, (snapshot) => {
              snapshot.docChanges().forEach(change => {
                if (change.type === "added") {
                  setNotifications(prev => [...prev, {
                    id: change.doc.id,
                    wine: change.doc.data(),
                    type: 'new_wine',
                    timestamp: new Date()
                  }]);
                }
              });
            });

            return () => unsubscribe();
          }
        }
      } catch (error) {
        console.error('Error fetching preferences:', error);
      }
    };

    fetchPreferences();
  }, [currentUser]);

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {notifications.map(notification => (
        <div
          key={notification.id}
          className="bg-white shadow-lg rounded-lg p-4 mb-2 max-w-sm animate-slide-in"
        >
          <h4 className="font-bold">{notification.wine.name}</h4>
          <p className="text-sm text-gray-600">
            New wine matching your preferences!
          </p>
        </div>
      ))}
    </div>
  );
};

export default WineNotifications; 