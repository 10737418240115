import React, { useState, useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { collection, query, orderBy, getDocs, doc, getDoc } from 'firebase/firestore';

const RestaurantHistory = () => {
  const { t } = useLanguage();
  const { currentUser } = useAuth();
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        const isUserAdmin = userDoc.data()?.isAdmin === true;
        setIsAdmin(isUserAdmin);

        if (isUserAdmin) {
          fetchRestaurants();
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
        setLoading(false);
      }
    };

    checkAdminStatus();
  }, [currentUser]);

  const fetchRestaurants = async () => {
    try {
      console.log('Fetching restaurants...');
      const q = query(
        collection(db, 'restaurants'),
        orderBy('lastUpdated', 'desc')
      );
      
      const snapshot = await getDocs(q);
      console.log('Found restaurants:', snapshot.docs.length);
      
      const restaurantData = [];
      
      for (const doc of snapshot.docs) {
        const restaurant = doc.data();
        console.log('Restaurant:', restaurant);
        
        if (!restaurant.menus?.[0]) {
          console.log('No menus found for restaurant:', restaurant.name);
          continue;
        }

        const latestMenuRef = doc(db, 'menus', restaurant.menus[0]);
        const menuDoc = await getDoc(latestMenuRef);
        
        if (!menuDoc.exists()) {
          console.log('Menu not found:', restaurant.menus[0]);
          continue;
        }

        console.log('Menu data:', menuDoc.data());
        
        restaurantData.push({
          id: doc.id,
          ...restaurant,
          latestMenu: menuDoc.data()
        });
      }
      
      console.log('Final restaurant data:', restaurantData);
      setRestaurants(restaurantData);
    } catch (error) {
      console.error('Error fetching restaurants:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!isAdmin) return null; // Don't render anything for non-admins

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">{t('restaurantHistory')}</h2>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        {restaurants.map(restaurant => (
          <div key={restaurant.id} className="border rounded-lg p-4">
            <h3 className="font-bold text-lg">{restaurant.name}</h3>
            {restaurant.location && (
              <p className="text-gray-600">{restaurant.location}</p>
            )}
            <p className="text-sm text-gray-500">
              {t('lastUpdated')}: {new Date(restaurant.lastUpdated).toLocaleDateString()}
            </p>
            <p className="text-sm">
              {t('winesInMenu')}: {Object.keys(restaurant.latestMenu.wines).length}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RestaurantHistory; 