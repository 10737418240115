import React, { useState, useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import Register from './Register';
import Login from './Login';
import { FiCamera, FiHeart, FiCompass, FiBook } from 'react-icons/fi';
import LanguageSelector from './LanguageSelector';
import { Link, useNavigate } from 'react-router-dom';
import { trackSignUpClick } from '../utils/metaTracking';
import { useAuth } from '../contexts/AuthContext';

const LandingPage = () => {
  const [showAuth, setShowAuth] = useState(null);
  const { t } = useLanguage();
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();

  // Add OS detection
  const detectOS = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    
    if (userAgent.includes('iphone') || userAgent.includes('ipad') || userAgent.includes('ipod')) {
      return 'iOS';
    } else if (userAgent.includes('android')) {
      return 'Android';
    } else if (userAgent.includes('mac os')) {
      return 'macOS';
    } else if (userAgent.includes('windows')) {
      return 'Windows';
    } else if (userAgent.includes('linux')) {
      return 'Linux';
    }
    return 'Unknown';
  };

  useEffect(() => {
    // Log OS on page load
    const userOS = detectOS();
    console.log('User OS:', userOS);

    if (currentUser) {
      setShowAuth(null);
      if (userOS === 'iOS') {
        // Redirect iOS users to App Store
        window.location.href = 'https://apps.apple.com/ca/app/glugou-wine-recommendations/id6740251395';
      } else {
        // Regular homepage redirect for non-iOS users
        navigate('/');
      }
    }
  }, [currentUser, navigate]);

  const features = [
    {
      icon: <FiCamera className="w-8 h-8 text-purple-600" />,
      title: t('scanFeatureTitle'),
      description: t('scanFeatureDesc')
    },
    {
      icon: <FiCompass className="w-8 h-8 text-purple-600" />,
      title: t('preferencesFeatureTitle'),
      description: t('preferencesFeatureDesc')
    },
    {
      icon: <FiBook className="w-8 h-8 text-purple-600" />,
      title: t('sommelierFeatureTitle'),
      description: t('sommelierFeatureDesc')
    },
    {
      icon: <FiHeart className="w-8 h-8 text-purple-600" />,
      title: t('trackingFeatureTitle'),
      description: t('trackingFeatureDesc')
    }
  ];

  const handleSignUpClick = () => {
    trackSignUpClick();
    setShowAuth('register');
  };

  const handleAuthSuccess = () => {
    setShowAuth(null);
  };

  const handleAuthClose = () => {
    setShowAuth(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/landing');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white overflow-auto">
      {/* Header */}
      <header className="bg-gradient-to-r from-purple-900 to-purple-600 text-white shadow-lg sticky top-0 z-10">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <Link to="/" className="text-xl font-bold hover:text-gray-200 transition-colors">
              Glugou
            </Link>
            <div className="flex items-center space-x-4">
              <LanguageSelector />
              {currentUser ? (
                <button
                  onClick={handleLogout}
                  className="bg-white text-purple-900 px-3 py-1.5 text-sm md:px-4 md:py-2 md:text-base rounded-lg hover:bg-gray-100 transition-colors"
                >
                  {t('logout')}
                </button>
              ) : (
                <>
                  <button
                    onClick={() => setShowAuth('login')}
                    className="text-white hover:text-gray-200 px-3 py-1.5 text-sm md:px-4 md:py-2 md:text-base rounded-lg transition-colors"
                  >
                    {t('login')}
                  </button>
                  <button
                    onClick={() => setShowAuth('register')}
                    className="bg-white text-purple-900 px-3 py-1.5 text-sm md:px-4 md:py-2 md:text-base rounded-lg hover:bg-gray-100 transition-colors"
                  >
                    {t('signUp')}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* Main content */}
      <main className="relative">
        {/* Hero Section */}
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-3xl mx-auto text-center flex flex-col items-center">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-2">
              {t('landingHeroTitle')}
            </h1>
            <p className="text-xl text-gray-600 mb-4">
              {t('landingHeroSubtitle')}
            </p>
            <button
              onClick={handleSignUpClick}
              className="bg-purple-600 text-white px-8 py-3 rounded-lg text-lg font-semibold hover:bg-purple-700 transition-colors shadow-lg"
            >
              {t('signUpNow')}
            </button>
            <div className="mt-3 bg-purple-100 border border-purple-300 rounded-md px-4 py-2">
              <p className="text-sm text-purple-800 font-medium">
                ✨ {t('earlyAccessPromo')} ✨
              </p>
            </div>
          </div>
        </div>

        {/* Features Section */}
        <div className="bg-white py-6">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-4">
              {t('whyChooseGlugou')}
            </h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
              {features.map((feature, index) => (
                <div
                  key={index}
                  className="p-4 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow"
                >
                  <div className="mb-2">{feature.icon}</div>
                  <h3 className="text-lg font-semibold mb-1">{feature.title}</h3>
                  <p className="text-gray-600 text-sm">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Social Proof Section */}
        <div className="py-6 bg-purple-50">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-4">
              {t('trustedByWineLovers')}
            </h2>
            <div className="flex flex-wrap justify-center gap-4">
              {/* Add testimonials or stats here */}
            </div>
          </div>
        </div>

        {/* Final CTA Section */}
        <div className="bg-purple-900 text-white py-8">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-2">
              {t('readyToStart')}
            </h2>
            <p className="text-xl mb-4">
              {t('startJourney')}
            </p>
            <button
              onClick={handleSignUpClick}
              className="bg-white text-purple-900 px-8 py-3 rounded-lg text-lg font-semibold hover:bg-gray-100 transition-colors shadow-lg"
            >
              {t('signUpNow')}
            </button>
          </div>
        </div>
      </main>

      {/* Auth Modals */}
      {showAuth === 'register' && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <Register 
            onClose={handleAuthClose}
            onBackToLogin={() => setShowAuth('login')}
            onSuccess={handleAuthSuccess}
          />
        </div>
      )}
      {showAuth === 'login' && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <Login 
            onClose={handleAuthClose}
            onSignUpClick={() => setShowAuth('register')}
            onSuccess={handleAuthSuccess}
          />
        </div>
      )}
    </div>
  );
};

export default LandingPage; 