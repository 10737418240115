import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { collection, query, orderBy, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useLanguage } from '../contexts/LanguageContext';

const MenuHistory = () => {
  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [menuToDelete, setMenuToDelete] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const { currentUser } = useAuth();
  const { t } = useLanguage();

  useEffect(() => {
    const fetchMenus = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const menusRef = collection(db, 'users', currentUser.uid, 'scannedMenus');
        const q = query(menusRef, orderBy('scannedAt', 'desc'));
        const querySnapshot = await getDocs(q);
        
        const menusList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setMenus(menusList);
      } catch (error) {
        console.error('Error fetching menus:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMenus();
  }, [currentUser]);

  const handleDeleteMenu = async (menu) => {
    if (!currentUser) return;

    try {
      setDeleting(true);
      const menuRef = doc(db, 'users', currentUser.uid, 'scannedMenus', menu.id);
      await deleteDoc(menuRef);
      
      // Update local state
      setMenus(menus.filter(m => m.id !== menu.id));
      setMenuToDelete(null);
      
      // If we're viewing the menu we just deleted, go back to list
      if (selectedMenu?.id === menu.id) {
        setSelectedMenu(null);
      }
    } catch (error) {
      console.error('Error deleting menu:', error);
    } finally {
      setDeleting(false);
    }
  };

  // Delete Confirmation Modal
  const DeleteConfirmation = ({ menu, onCancel, onConfirm }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <h3 className="text-lg font-bold mb-4">Delete Menu?</h3>
        <p className="text-gray-600 mb-6">
          Are you sure you want to delete the menu from {menu.restaurantName}? 
          This action cannot be undone.
        </p>
        <div className="flex justify-end gap-3">
          <button
            onClick={onCancel}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
            disabled={deleting}
          >
            Cancel
          </button>
          <button
            onClick={() => onConfirm(menu)}
            disabled={deleting}
            className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
          >
            {deleting ? 'Deleting...' : 'Delete'}
          </button>
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="text-center py-10">
        Loading menu history...
      </div>
    );
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  };

  if (selectedMenu) {
    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <button
            onClick={() => setSelectedMenu(null)}
            className="flex items-center text-purple-600 hover:text-purple-800 font-medium"
          >
            <svg className="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
            Back to Menu History
          </button>
          <span className="text-sm text-gray-500">
            {formatDate(selectedMenu.scannedAt)}
          </span>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="mb-6">
            <h2 className="text-xl font-bold text-gray-800">
              {selectedMenu.customName || selectedMenu.restaurantName}
            </h2>
            {selectedMenu.location && (
              <p className="text-gray-600 mt-1">{selectedMenu.location}</p>
            )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {Object.values(selectedMenu.wines).map(wine => (
              <div 
                key={wine.name}
                className="p-4 border rounded-lg"
              >
                <h3 className="font-bold text-lg mb-2">{wine.name}</h3>
                <div className="space-y-2 text-sm">
                  {wine.type && (
                    <div className="flex">
                      <span className="text-gray-500 w-20">Type:</span>
                      <span className="text-gray-900">{wine.type}</span>
                    </div>
                  )}
                  {wine.varietal && (
                    <div className="flex">
                      <span className="text-gray-500 w-20">Varietal:</span>
                      <span className="text-gray-900">{wine.varietal}</span>
                    </div>
                  )}
                  {wine.region && (
                    <div className="flex">
                      <span className="text-gray-500 w-20">Region:</span>
                      <span className="text-gray-900">{wine.region}</span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-bold text-gray-800">Saved Menus</h2>
      
      {menus.length === 0 ? (
        <div className="text-center py-10 text-gray-500">
          No saved menus yet
        </div>
      ) : (
        <div className="grid gap-4">
          {menus.map(menu => (
            <div
              key={menu.id}
              className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="p-4">
                <div className="flex justify-between items-start">
                  <button
                    onClick={() => setSelectedMenu(menu)}
                    className="flex-1 text-left"
                  >
                    <div className="font-medium">
                      {menu.customName || menu.restaurantName}
                    </div>
                    {menu.location && (
                      <div className="text-sm text-gray-600 mt-1">
                        {menu.location}
                      </div>
                    )}
                    <div className="text-sm text-gray-500 mt-1">
                      {Object.keys(menu.wines).length} {Object.keys(menu.wines).length === 1 ? 'wine' : 'wines'}
                      {' • '}{formatDate(menu.scannedAt)}
                    </div>
                  </button>
                  <div className="flex items-center gap-2 ml-4">
                    <button
                      onClick={() => setMenuToDelete(menu)}
                      className="p-2 text-gray-400 hover:text-red-600 rounded-full hover:bg-gray-100 transition-colors"
                      title="Delete menu"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                        />
                      </svg>
                    </button>
                    <button
                      onClick={() => setSelectedMenu(menu)}
                      className="p-2 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100 transition-colors"
                      title="View menu"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {menuToDelete && (
        <DeleteConfirmation
          menu={menuToDelete}
          onCancel={() => setMenuToDelete(null)}
          onConfirm={handleDeleteMenu}
        />
      )}
    </div>
  );
};

export default MenuHistory; 