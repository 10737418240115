import React, { useState, useMemo, useEffect } from 'react';
import Header from './components/Header';
import SearchSection from './components/SearchSection';
import FiltersSidebar from './components/FiltersSidebar';
import WineCard from './components/WineCard';
import Favorites from './components/Favorites';
import WineNotifications from './components/WineNotifications';
import WinePreferences from './components/WinePreferences';
import WineRecommendations from './components/WineRecommendations';
import SaveMenuModal from './components/SaveMenuModal';
import { useAuth } from './contexts/AuthContext';
import { doc, setDoc, collection, query, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import MenuHistory from './components/MenuHistory';
import { LanguageProvider, useLanguage } from './contexts/LanguageContext';
import LanguageSelector from './components/LanguageSelector';
import AddToHomeScreen from './components/AddToHomeScreen';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import RestaurantHistory from './components/RestaurantHistory';
import Login from './components/Login';
import Register from './components/Register';
import Preferences from './components/Preferences';
import PrivacyPolicy from './components/PrivacyPolicy';
import ContactSupport from './components/ContactSupport';
import Footer from './components/Footer';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

function AppRoutes() {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route path="/landing" element={<LandingPage />} />
      <Route path="/" element={<MainApp />} />
      <Route path="/login" element={<Login onClose={() => navigate('/')} />} />
      <Route path="/preferences" element={<Preferences />} />
      <Route path="/favorites" element={<Favorites />} />
      <Route path="/admin/restaurants" element={<RestaurantHistory />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/contact" element={<ContactSupport />} />
    </Routes>
  );
}

function MainApp() {
  const { t } = useLanguage();
  const [wines, setWines] = useState(() => {
    const savedWines = localStorage.getItem('wines');
    return savedWines ? JSON.parse(savedWines) : {};
  });
  const [currentPage, setCurrentPage] = useState('home');
  const [activeFilters, setActiveFilters] = useState({
    type: '',
    region: '',
    traits: []
  });
  const [showFilters, setShowFilters] = useState(false);
  const [showSaveMenu, setShowSaveMenu] = useState(false);
  const { currentUser } = useAuth();
  const [saving, setSaving] = useState(false);
  const [menuSaved, setMenuSaved] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [recommendedWines, setRecommendedWines] = useState([]);
  const [userPreferences, setUserPreferences] = useState(null);
  const [showSearchScan, setShowSearchScan] = useState(true);
  const [currentRestaurant, setCurrentRestaurant] = useState(() => {
    const savedRestaurant = localStorage.getItem('currentRestaurant');
    return savedRestaurant ? JSON.parse(savedRestaurant) : null;
  });

  const isSingleWine = Object.keys(wines).length === 1;

  useEffect(() => {
    localStorage.setItem('wines', JSON.stringify(wines));
  }, [wines]);

  useEffect(() => {
    if (currentRestaurant) {
      localStorage.setItem('currentRestaurant', JSON.stringify(currentRestaurant));
    } else {
      localStorage.removeItem('currentRestaurant');
    }
  }, [currentRestaurant]);

  useEffect(() => {
    // Hide search interface when wines are loaded
    if (Object.keys(wines).length > 0) {
      setShowSearchScan(false);
    }
  }, [wines]);

  const handleMenuUpload = (data, source, restaurantData) => {
    setWines(data);
    setActiveFilters({
      type: '',
      region: '',
      traits: []
    });
    setShowFilters(false);
    setShowSearchScan(false);
    if (restaurantData) {
      setCurrentRestaurant({
        ...restaurantData,
        visitDate: restaurantData.visitDate || new Date().toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
      });
    }
  };

  const handleFilterChange = (filterType, value) => {
    setActiveFilters(prev => ({
      ...prev,
      [filterType]: value
    }));
  };

  const handleClearMenu = () => {
    setWines({});
    setCurrentRestaurant(null);
    localStorage.removeItem('wines');
    localStorage.removeItem('currentRestaurant');
    setShowFilters(false);
    setShowSearchScan(true);
  };

  const handleNavigate = (page) => {
    setCurrentPage(page);
    setShowFilters(false);
  };

  const calculateWineScore = (wine, userPreferences) => {
    if (!userPreferences) return 0;
    let score = 0;

    // Type match (highest weight as it's most important)
    if (wine.type && userPreferences.types[wine.type.toLowerCase()]) {
      score += userPreferences.types[wine.type.toLowerCase()] * 10;
    }

    // Region match
    if (wine.region && userPreferences.regions[wine.region]) {
      score += userPreferences.regions[wine.region] * 8;
    }

    // Varietal match
    if (wine.varietal && userPreferences.varietals[wine.varietal]) {
      score += userPreferences.varietals[wine.varietal] * 8;
    }

    // Characteristics/traits matches
    if (wine.description) {
      const traits = wine.description.toLowerCase().split(',').map(t => t.trim());
      traits.forEach(trait => {
        if (userPreferences.characteristics[trait]) {
          score += userPreferences.characteristics[trait] * 5;
        }
      });
    }

    // Boost score for previously liked wines of similar characteristics
    if (userPreferences.likedWines) {
      const similarityScore = calculateSimilarityToLikedWines(wine, userPreferences.likedWines);
      score += similarityScore * 3;
    }

    return score;
  };

  const calculateSimilarityToLikedWines = (wine, likedWines) => {
    let similarityScore = 0;
    
    likedWines.forEach(likedWine => {
      // Type match
      if (wine.type && wine.type.toLowerCase() === likedWine.type?.toLowerCase()) {
        similarityScore += 2;
      }
      
      // Region match
      if (wine.region && wine.region === likedWine.region) {
        similarityScore += 2;
      }
      
      // Varietal match
      if (wine.varietal && wine.varietal === likedWine.varietal) {
        similarityScore += 2;
      }
      
      // Characteristics overlap
      const wineTraits = wine.description?.toLowerCase().split(',').map(t => t.trim()) || [];
      const likedTraits = likedWine.description?.toLowerCase().split(',').map(t => t.trim()) || [];
      const commonTraits = wineTraits.filter(trait => likedTraits.includes(trait));
      similarityScore += commonTraits.length;
    });

    return similarityScore;
  };

  useEffect(() => {
    const fetchUserPreferences = async () => {
      if (!currentUser) {
        setUserPreferences(null);
        return;
      }

      try {
        // Get saved wines
        const savedWinesRef = collection(db, 'users', currentUser.uid, 'savedWines');
        const savedWinesSnap = await getDocs(savedWinesRef);
        const savedWines = savedWinesSnap.docs.map(doc => doc.data());

        // Get disliked wines
        const dislikedWinesRef = collection(db, 'users', currentUser.uid, 'dislikedWines');
        const dislikedWinesSnap = await getDocs(dislikedWinesRef);
        const dislikedWines = dislikedWinesSnap.docs.map(doc => doc.data());

        // Calculate preferences
        const preferences = {
          types: {},
          regions: {},
          varietals: {},
          characteristics: {},
          likedWines: savedWines,
          dislikedWines: dislikedWines
        };

        // Count preferences from saved wines
        savedWines.forEach(wine => {
          if (wine.type) {
            preferences.types[wine.type.toLowerCase()] = 
              (preferences.types[wine.type.toLowerCase()] || 0) + 1;
          }
          if (wine.region) {
            preferences.regions[wine.region] = 
              (preferences.regions[wine.region] || 0) + 1;
          }
          if (wine.varietal) {
            preferences.varietals[wine.varietal] = 
              (preferences.varietals[wine.varietal] || 0) + 1;
          }
          if (wine.description) {
            wine.description.split(',').forEach(trait => {
              const cleanTrait = trait.trim().toLowerCase();
              preferences.characteristics[cleanTrait] = 
                (preferences.characteristics[cleanTrait] || 0) + 1;
            });
          }
        });

        setUserPreferences(preferences);
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      }
    };

    fetchUserPreferences();
  }, [currentUser]);

  const filteredWines = useMemo(() => {
    return Object.entries(wines)
      .filter(([_, wine]) => {
        // Apply filters
        if (activeFilters.type && wine.type?.toLowerCase() !== activeFilters.type.toLowerCase()) {
          return false;
        }
        if (activeFilters.region && wine.region?.toLowerCase() !== activeFilters.region.toLowerCase()) {
          return false;
        }
        if (activeFilters.traits.length > 0) {
          const wineTraits = wine.description?.toLowerCase().split(',').map(t => t.trim()) || [];
          return activeFilters.traits.some(trait => 
            wineTraits.some(wineTrait => wineTrait.includes(trait.toLowerCase()))
          );
        }
        return true;
      })
      .map(([key, wine]) => ({
        key,
        wine,
        score: calculateWineScore(wine, userPreferences)
      }))
      .sort((a, b) => b.score - a.score) // Sort by score
      .map(({ key, wine }) => [key, wine]); // Convert back to original format
  }, [wines, activeFilters, userPreferences]);

  const hasActiveFilters = (filters) => {
    return filters.type || filters.region || filters.traits?.length > 0;
  };

  const handleSaveMenu = async () => {
    if (!currentUser) {
      alert('Please log in to save menus');
      return;
    }

    try {
      const menuId = Date.now().toString();
      const menuRef = doc(db, 'users', currentUser.uid, 'scannedMenus', menuId);
      
      await setDoc(menuRef, {
        wines,
        scannedAt: new Date().toISOString(),
        menuId
      });

      setMenuSaved(true);
    } catch (error) {
      console.error('Error saving menu:', error);
    }
  };

  const analyzeUserPreferences = async () => {
    if (!currentUser) return null;

    try {
      const savedWinesRef = collection(db, 'users', currentUser.uid, 'savedWines');
      const querySnapshot = await getDocs(savedWinesRef);
      
      const preferences = {
        types: {},
        regions: {},
        varietals: {},
        characteristics: {}
      };

      querySnapshot.forEach(doc => {
        const wine = doc.data();
        // Count wine types
        if (wine.type) {
          preferences.types[wine.type.toLowerCase()] = 
            (preferences.types[wine.type.toLowerCase()] || 0) + 1;
        }
        // Count regions
        if (wine.region) {
          preferences.regions[wine.region] = 
            (preferences.regions[wine.region] || 0) + 1;
        }
        // Count varietals
        if (wine.varietal) {
          preferences.varietals[wine.varietal] = 
            (preferences.varietals[wine.varietal] || 0) + 1;
        }
        // Count characteristics from descriptions
        if (wine.description) {
          wine.description.split(',').forEach(trait => {
            const cleanTrait = trait.trim().toLowerCase();
            preferences.characteristics[cleanTrait] = 
              (preferences.characteristics[cleanTrait] || 0) + 1;
          });
        }
      });

      return preferences;
    } catch (error) {
      console.error('Error analyzing preferences:', error);
      return null;
    }
  };

  const getWineScore = (wine, preferences) => {
    if (!preferences) return 0;
    
    let score = 0;
    
    // Type match
    if (wine.type && preferences.types[wine.type.toLowerCase()]) {
      score += 3;
    }
    
    // Region match
    if (wine.region && preferences.regions[wine.region]) {
      score += 2;
    }
    
    // Varietal match
    if (wine.varietal && preferences.varietals[wine.varietal]) {
      score += 2;
    }
    
    // Characteristics matches
    if (wine.description) {
      wine.description.split(',').forEach(trait => {
        const cleanTrait = trait.trim().toLowerCase();
        if (preferences.characteristics[cleanTrait]) {
          score += 1;
        }
      });
    }
    
    return score;
  };

  const renderWineList = () => {
    if (filteredWines.length === 0) {
      return (
        <div className="text-center py-10 text-gray-500">
          {Object.keys(wines).length === 0 
            ? 'Upload a menu to see the wine list'
            : 'No wines match the selected filters'}
        </div>
      );
    }

    const recommendedWinesList = filteredWines.filter(([key]) => 
      recommendedWines.includes(key));
    const otherWinesList = filteredWines.filter(([key]) => 
      !recommendedWines.includes(key));

    return (
      <div className="space-y-8">
        {recommendedWinesList.length > 0 && (
          <div>
            <h2 className="text-lg font-semibold text-purple-600 mb-4 flex items-center gap-2">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
              Recommended for You
            </h2>
            <div className={`grid gap-4 sm:gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3`}>
              {recommendedWinesList.map(([key, wine]) => (
                <WineCard 
                  key={key} 
                  wine={wine}
                  isRecommended={true}
                />
              ))}
            </div>
          </div>
        )}

        {otherWinesList.length > 0 && (
          <div>
            {recommendedWinesList.length > 0 && (
              <h2 className="text-lg font-semibold text-gray-600 mb-4">
                Other Wines
              </h2>
            )}
            <div className={`grid gap-4 sm:gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3`}>
              {otherWinesList.map(([key, wine]) => (
                <WineCard 
                  key={key} 
                  wine={wine}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleShowSearchScan = () => {
    setShowSearchScan(true);
  };

  const renderContent = () => {
    switch (currentPage) {
      case 'preferences':
        return (
          <div className="mt-6">
            <WinePreferences />
          </div>
        );
      case 'favorites':
        return (
          <div className="mt-6 space-y-12">
            <Favorites />
            <WineRecommendations />
          </div>
        );
      case 'menuHistory':
        return (
          <div className="mt-6">
            <MenuHistory />
          </div>
        );
      default:
        return (
          <>
            <SearchSection 
              onMenuUpload={handleMenuUpload}
              showSearch={showSearchScan}
              onShowSearch={handleShowSearchScan}
              restaurantInfo={currentRestaurant}
            />
            {Object.keys(wines).length > 0 && (
              <div className="mt-6">
                <div className="flex justify-between items-center mb-4 md:hidden">
                  {!isSingleWine && (
                    <button
                      onClick={() => setShowFilters(!showFilters)}
                      className="flex items-center gap-2 text-purple-600 font-medium"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                          d={showFilters 
                            ? "M6 18L18 6M6 6l12 12" 
                            : "M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z"} 
                        />
                      </svg>
                      {showFilters ? t('hideFilters') : t('showFilters')}
                    </button>
                  )}
                  <div className="flex gap-4">
                    <button
                      onClick={() => setShowSaveModal(true)}
                      className="text-purple-600 hover:text-purple-800 text-sm font-medium"
                    >
                      {t('saveMenu')}
                    </button>
                    <button
                      onClick={handleClearMenu}
                      className="text-red-600 hover:text-red-800 text-sm font-medium"
                    >
                      {t('clearMenu')}
                    </button>
                  </div>
                </div>

                <div className="relative flex flex-col md:flex-row gap-6">
                  {!isSingleWine && showFilters && (
                    <div className="md:hidden fixed inset-0 z-40">
                      <div 
                        className="absolute inset-0 bg-black bg-opacity-50" 
                        onClick={() => setShowFilters(false)}
                        aria-label="Close filters overlay"
                      />
                      
                      <div className="absolute right-0 top-16 bottom-0 left-0 w-80 bg-white overflow-hidden flex flex-col">
                        <div className="sticky top-0 bg-white z-10 px-6 py-4 border-b flex justify-between items-center">
                          <h2 className="font-bold text-xl">Filters</h2>
                          <button
                            onClick={() => setShowFilters(false)}
                            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                            aria-label="Close filters"
                          >
                            <svg 
                              className="w-6 h-6 text-gray-500" 
                              fill="none" 
                              stroke="currentColor" 
                              viewBox="0 0 24 24"
                            >
                              <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth={2} 
                                d="M6 18L18 6M6 6l12 12" 
                              />
                            </svg>
                          </button>
                        </div>
                        <div className="flex-1 overflow-y-auto p-6">
                          <FiltersSidebar 
                            onFilterChange={handleFilterChange}
                            activeFilters={activeFilters}
                            wines={wines}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {!isSingleWine && (
                    <div className="hidden md:block w-64">
                      <FiltersSidebar 
                        onFilterChange={handleFilterChange}
                        activeFilters={activeFilters}
                        wines={wines}
                      />
                    </div>
                  )}

                  <main className={`flex-1 ${isSingleWine ? 'max-w-2xl mx-auto' : ''}`}>
                    {hasActiveFilters(activeFilters) && !isSingleWine && (
                      <div className="mb-4 flex items-center">
                        <button
                          onClick={() => {
                            setActiveFilters({
                              type: '',
                              region: '',
                              traits: []
                            });
                          }}
                          className="flex items-center gap-2 text-sm text-red-600 hover:text-red-800 font-medium"
                        >
                          <svg 
                            className="w-4 h-4" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              strokeWidth={2} 
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                          Reset Filters
                        </button>
                        <span className="ml-3 text-sm text-gray-500">
                          {filteredWines.length} {filteredWines.length === 1 ? 'wine' : 'wines'} found
                        </span>
                      </div>
                    )}

                    {filteredWines.length > 0 ? renderWineList() : (
                      <div className="text-center py-10 text-gray-500">
                        {Object.keys(wines).length === 0 
                          ? 'Upload a menu to see the wine list'
                          : 'No wines match the selected filters'}
                      </div>
                    )}
                  </main>
                </div>
              </div>
            )}
          </>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Header onNavigate={handleNavigate} currentPage={currentPage} />
      <div className="container mx-auto px-4 flex-1">
        {renderContent()}
      </div>
      <Footer />
      {currentUser && <WineNotifications />}
      {showSaveMenu && (
        <SaveMenuModal
          wines={wines}
          onClose={() => setShowSaveMenu(false)}
          onSave={() => {
            // Optional: Add success message
          }}
        />
      )}
      {showSaveModal && (
        <SaveMenuModal
          wines={wines}
          onClose={() => setShowSaveModal(false)}
        />
      )}
    </div>
  );
}

function AppWithLanguage() {
  return (
    <LanguageProvider>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </LanguageProvider>
  );
}

export default AppWithLanguage;