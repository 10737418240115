import { META_CONFIG } from './metaConfig';

const META_PIXEL_ID = META_CONFIG.PIXEL_ID;
const META_ACCESS_TOKEN = process.env.REACT_APP_META_ACCESS_TOKEN;

console.log('Environment check:', {
  PIXEL_ID: process.env.REACT_APP_META_PIXEL_ID,
  NODE_ENV: process.env.NODE_ENV,
  ALL_ENV: process.env // This will show all available env variables
});

console.log('Meta Pixel ID:', META_PIXEL_ID);

// Add hashing function
const hashData = (data) => {
  if (!data) return null;
  const crypto = window.crypto || window.msCrypto;
  const encoder = new TextEncoder();
  const data_buffer = encoder.encode(data.toLowerCase().trim());
  
  return crypto.subtle.digest('SHA-256', data_buffer).then(hash => {
    return Array.from(new Uint8Array(hash))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');
  });
};

export const trackSignUpClick = async () => {
  const eventTime = Math.floor(Date.now() / 1000);
  const eventId = `signup_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

  // Client-side tracking with deduplication
  if (window.fbq) {
    window.fbq('track', 'Lead', {
      eventID: eventId,
      event_time: eventTime,
      action_source: 'website',
      event_source_url: window.location.href
    });
  }

  // Get browser data for better matching
  const userAgent = navigator.userAgent;
  const fbp = document.cookie.match(/_fbp=([^;]+)/)?.pop() || null;
  const fbc = document.cookie.match(/fbclid=([^;]+)/)?.pop() || null;
  const url = window.location.href;

  // Server-side tracking using Conversions API
  try {
    console.log('Sending event with Pixel ID:', META_PIXEL_ID);
    const response = await fetch(`https://graph.facebook.com/v17.0/${META_PIXEL_ID}/events`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${META_ACCESS_TOKEN}`
      },
      body: JSON.stringify({
        data: [{
          event_name: 'Lead',
          event_time: eventTime,
          event_id: eventId,
          event_source_url: url,
          action_source: 'website',
          user_data: {
            client_user_agent: userAgent,
            fbp: fbp,
            fbc: fbc
          },
          custom_data: {
            content_name: 'Landing Page Signup',
            content_category: 'Registration',
            status: 'initiated'
          }
        }]
      })
    });
    
    if (!response.ok) {
      const errorText = await response.text();
      console.error('Full error response:', errorText);
      throw new Error(errorText);
    }
  } catch (error) {
    console.error('Full error details:', error);
    throw error;
  }
};

export const trackRegistrationComplete = async (userData) => {
  const eventTime = Math.floor(Date.now() / 1000);
  const eventId = `registration_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

  // Hash all PII data
  const [hashedEmail, hashedFirstName, hashedLastName, hashedCountry] = await Promise.all([
    hashData(userData.email),
    hashData(userData.firstName),
    hashData(userData.lastName),
    hashData(userData.country)
  ]);

  // Client-side tracking with deduplication
  if (window.fbq) {
    window.fbq('track', 'CompleteRegistration', {
      eventID: eventId,
      event_time: eventTime,
      action_source: 'website',
      event_source_url: window.location.href
    });
  }

  // Server-side tracking using Conversions API
  try {
    const response = await fetch(`https://graph.facebook.com/v17.0/${META_PIXEL_ID}/events`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${META_ACCESS_TOKEN}`
      },
      body: JSON.stringify({
        data: [{
          event_name: 'CompleteRegistration',
          event_time: eventTime,
          event_id: eventId,
          event_source_url: window.location.href,
          action_source: 'website',
          user_data: {
            em: [hashedEmail],
            fn: [hashedFirstName],
            ln: [hashedLastName],
            ct: [hashedCountry],
            client_user_agent: navigator.userAgent,
            fbp: document.cookie.match(/_fbp=([^;]+)/)?.pop() || null,
            fbc: document.cookie.match(/fbclid=([^;]+)/)?.pop() || null
          },
          custom_data: {
            content_name: 'User Registration',
            content_category: 'Registration',
            status: 'completed'
          }
        }]
      })
    });
    
    if (!response.ok) {
      const errorText = await response.text();
      console.error('Full error response:', errorText);
      throw new Error(errorText);
    }
  } catch (error) {
    console.error('Full error details:', error);
    throw error;
  }
};

export const trackAppDownloadClick = async () => {
  const eventTime = Math.floor(Date.now() / 1000);
  const eventId = `app_download_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

  // Client-side tracking with deduplication
  if (window.fbq) {
    window.fbq('track', 'AppDownloadClick', {
      eventID: eventId,
      event_time: eventTime,
      action_source: 'website',
      event_source_url: window.location.href
    });
  }

  // Get browser data for better matching
  const userAgent = navigator.userAgent;
  const fbp = document.cookie.match(/_fbp=([^;]+)/)?.pop() || null;
  const fbc = document.cookie.match(/fbclid=([^;]+)/)?.pop() || null;
  const url = window.location.href;

  // Server-side tracking using Conversions API
  try {
    const response = await fetch(`https://graph.facebook.com/v17.0/${META_PIXEL_ID}/events`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${META_ACCESS_TOKEN}`
      },
      body: JSON.stringify({
        data: [{
          event_name: 'AppDownloadClick',
          event_time: eventTime,
          event_id: eventId,
          event_source_url: url,
          action_source: 'website',
          user_data: {
            client_user_agent: userAgent,
            fbp: fbp,
            fbc: fbc
          },
          custom_data: {
            content_name: 'App Store Download',
            content_category: 'App Download',
            status: 'initiated'
          }
        }]
      })
    });
    
    if (!response.ok) {
      const errorText = await response.text();
      console.error('Full error response:', errorText);
      throw new Error(errorText);
    }
  } catch (error) {
    console.error('Full error details:', error);
    // Don't throw the error to prevent disrupting user experience
    console.error('Error tracking app download click:', error);
  }
}; 