import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import WineCard from './WineCard';
import { useLanguage } from '../contexts/LanguageContext';

export default function Favorites() {
  const [savedWines, setSavedWines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState('country'); // 'country' or 'type'
  const { currentUser } = useAuth();
  const { t } = useLanguage();

  // Helper function to convert country codes to full names
  const getCountryName = (code) => {
    const countryMap = {
      'FR': 'France',
      'IT': 'Italy',
      'ES': 'Spain',
      'PT': 'Portugal',
      'DE': 'Germany',
      'US': 'United States',
      'AR': 'Argentina',
      'CL': 'Chile',
      'AU': 'Australia',
      'NZ': 'New Zealand',
      'ZA': 'South Africa',
      'AT': 'Austria',
      'GR': 'Greece',
      'HU': 'Hungary'
    };
    
    // If it's a 2-letter code, return the full name or the code if not found
    if (code?.length === 2) {
      return countryMap[code.toUpperCase()] || code;
    }
    
    // If it's not a 2-letter code, return the original value
    return code;
  };

  // Group wines by country
  const groupWinesByCountry = (wines) => {
    return wines.reduce((acc, wine) => {
      let country = wine.country;
      
      // If no country is specified, try to extract it from the region
      if (!country && wine.region) {
        const parts = wine.region.split(',').map(part => part.trim());
        if (parts.length > 1) {
          country = getCountryName(parts[parts.length - 1]);
        }
      }

      // Default to 'Other' if no country could be determined
      country = country ? getCountryName(country) : 'Other';
      
      if (!acc[country]) {
        acc[country] = [];
      }
      acc[country].push(wine);
      return acc;
    }, {});
  };

  // Group wines by type
  const groupWinesByType = (wines) => {
    return wines.reduce((acc, wine) => {
      // Capitalize first letter of type, or use 'Other'
      const type = wine.type 
        ? wine.type.charAt(0).toUpperCase() + wine.type.slice(1).toLowerCase()
        : 'Other';
      
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(wine);
      return acc;
    }, {});
  };

  useEffect(() => {
    const fetchSavedWines = async () => {
      if (!currentUser) {
        setSavedWines([]);
        setLoading(false);
        return;
      }

      try {
        const savedWinesRef = collection(db, 'users', currentUser.uid, 'savedWines');
        const q = query(savedWinesRef);
        const querySnapshot = await getDocs(q);
        
        const wines = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setSavedWines(wines);
      } catch (error) {
        console.error('Error fetching saved wines:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSavedWines();
  }, [currentUser]);

  if (loading) {
    return <div className="text-center py-10">{t('loading')}</div>;
  }

  if (!currentUser) {
    return (
      <div className="text-center py-10">
        <p className="text-gray-500">{t('pleaseLoginToSeeFavorites')}</p>
      </div>
    );
  }

  if (savedWines.length === 0) {
    return (
      <div className="text-center py-10">
        <p className="text-gray-500">{t('noFavorites')}</p>
      </div>
    );
  }

  const groupedWines = sortBy === 'country' 
    ? groupWinesByCountry(savedWines)
    : groupWinesByType(savedWines);

  const sortedGroups = Object.keys(groupedWines).sort((a, b) => {
    // Put 'Other' at the end
    if (a === 'Other') return 1;
    if (b === 'Other') return -1;

    // If sorting by type, sort by number of wines in each type
    if (sortBy === 'type') {
      return groupedWines[b].length - groupedWines[a].length;
    }

    // For countries, keep alphabetical sorting
    return a.localeCompare(b);
  });

  return (
    <div className="space-y-12">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8">
        <h2 className="text-2xl font-bold">{t('favorites')}</h2>
        
        <div className="flex items-center bg-white rounded-lg border border-gray-200 p-1">
          <button
            onClick={() => setSortBy('country')}
            className={`px-4 py-2 rounded-md text-sm font-medium transition-colors
              ${sortBy === 'country' 
                ? 'bg-purple-100 text-purple-800' 
                : 'text-gray-600 hover:bg-gray-50'}`}
          >
            {t('byCountry')}
          </button>
          <button
            onClick={() => setSortBy('type')}
            className={`px-4 py-2 rounded-md text-sm font-medium transition-colors
              ${sortBy === 'type' 
                ? 'bg-purple-100 text-purple-800' 
                : 'text-gray-600 hover:bg-gray-50'}`}
          >
            {t('byType')}
          </button>
        </div>
      </div>
      
      {sortedGroups.map(group => (
        <div key={group} className="space-y-4">
          <div className="flex items-center gap-2">
            <h3 className="text-xl font-semibold text-gray-800">
              {group}
            </h3>
            <span className="text-sm text-gray-500">
              ({groupedWines[group].length})
            </span>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {groupedWines[group]
              .sort((a, b) => {
                // If grouped by country, sort by region
                if (sortBy === 'country') {
                  return (a.region || '').localeCompare(b.region || '');
                }
                // If grouped by type, sort by country then region
                return (a.country || '').localeCompare(b.country || '') || 
                       (a.region || '').localeCompare(b.region || '');
              })
              .map(wine => (
                <WineCard key={wine.id} wine={wine} />
              ))}
          </div>
          
          <div className="border-b border-gray-200 mt-8"></div>
        </div>
      ))}
    </div>
  );
} 