import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import WineCard from './WineCard';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

const WineRecommendations = () => {
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchRecommendations = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const savedWinesRef = collection(db, 'users', currentUser.uid, 'savedWines');
        const savedWinesSnap = await getDocs(savedWinesRef);
        const savedWines = savedWinesSnap.docs.map(doc => doc.data());

        if (savedWines.length === 0) {
          setLoading(false);
          return;
        }

        // Get wine preferences
        const preferences = analyzeSavedWines(savedWines);

        // Updated API endpoint
        const response = await fetch(`${API_URL}/api/recommend`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            preferences,
            savedWines: savedWines.map(wine => wine.name)
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to get recommendations');
        }

        const data = await response.json();
        setRecommendations(data.recommendations || []);
      } catch (error) {
        console.error('Error fetching recommendations:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecommendations();
  }, [currentUser]);

  const analyzeSavedWines = (wines) => {
    const counts = {
      types: {},
      regions: {},
      varietals: {},
      characteristics: {}
    };

    wines.forEach(wine => {
      // Count wine types
      if (wine.type) {
        counts.types[wine.type] = (counts.types[wine.type] || 0) + 1;
      }

      // Count regions
      if (wine.region) {
        counts.regions[wine.region] = (counts.regions[wine.region] || 0) + 1;
      }

      // Count varietals
      if (wine.varietal) {
        counts.varietals[wine.varietal] = (counts.varietals[wine.varietal] || 0) + 1;
      }

      // Count characteristics
      if (wine.description) {
        wine.description.split(',').forEach(trait => {
          const cleanTrait = trait.trim().toLowerCase();
          counts.characteristics[cleanTrait] = (counts.characteristics[cleanTrait] || 0) + 1;
        });
      }
    });

    return {
      favoriteType: getTopPreference(counts.types),
      favoriteRegion: getTopPreference(counts.regions),
      favoriteVarietal: getTopPreference(counts.varietals),
      favoriteCharacteristics: getTopNPreferences(counts.characteristics, 3)
    };
  };

  const getTopPreference = (obj) => {
    return Object.entries(obj)
      .sort(([,a], [,b]) => b - a)[0]?.[0];
  };

  const getTopNPreferences = (obj, n) => {
    return Object.entries(obj)
      .sort(([,a], [,b]) => b - a)
      .slice(0, n)
      .map(([key]) => key);
  };

  if (loading) {
    return (
      <div className="text-center py-6">
        <div className="animate-pulse">Finding wines you might like...</div>
      </div>
    );
  }

  if (!currentUser) {
    return null;
  }

  if (recommendations.length === 0) {
    return null;
  }

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-bold text-gray-800">
        Recommended for You
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {recommendations.map((wine, index) => (
          <WineCard 
            key={index}
            wine={wine}
            isRecommendation={true}
          />
        ))}
      </div>
    </div>
  );
};

export default WineRecommendations;