import React, { useState, useRef } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import RestaurantSelectionModal from './RestaurantSelectionModal';
import { trackAppDownloadClick } from '../utils/metaTracking';

function SearchSection({ onMenuUpload, showSearch = true, onShowSearch, restaurantInfo }) {
  const { t } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const [showRestaurantModal, setShowRestaurantModal] = useState(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [pendingImageData, setPendingImageData] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const fileInputRef = useRef(null);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      alert(t('pleaseUploadImage'));
      return;
    }

    // Reset file input value so the same file can be selected again
    event.target.value = '';
    
    // Store the file data and show restaurant selection modal
    setPendingImageData(file);
    setShowRestaurantModal(true);
  };

  const processImage = async (file, restaurantData) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('myImage', file);
      if (restaurantData) {
        console.log('Sending restaurant data:', restaurantData);
        formData.append('restaurantInfo', JSON.stringify(restaurantData));
      }

      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
      const response = await fetch(`${apiUrl}/upload`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Upload failed: ${response.status}`);
      }

      const data = await response.json();
      // Filter out empty or invalid wines
      const validWines = Object.values(data.data || data).filter(wine => 
        wine && 
        wine.name && 
        (wine.type || wine.varietal || wine.region)  // At least one of these should exist
      );
      
      // Pass restaurant data to parent component
      onMenuUpload(validWines, 'scan', {
        ...restaurantData,
        restaurantId: data.restaurantId,
        menuId: data.menuId
      });
    } catch (error) {
      console.error('Error uploading menu:', error);
      alert(t('uploadError'));
    } finally {
      setIsLoading(false);
      setPendingImageData(null);
      setSelectedRestaurant(null);
    }
  };

  const handleRestaurantSelect = (restaurantData) => {
    setSelectedRestaurant(restaurantData);
    setShowRestaurantModal(false);
    if (pendingImageData) {
      processImage(pendingImageData, restaurantData);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchQuery.trim()) return;

    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
      const response = await fetch(`${apiUrl}/api/lookup?query=${encodeURIComponent(searchQuery)}`);
      
      if (!response.ok) {
        throw new Error(`Search failed: ${response.status}`);
      }

      const data = await response.json();
      onMenuUpload([data], 'search');
    } catch (error) {
      console.error('Error searching wine:', error);
      alert(t('searchError'));
    } finally {
      setIsLoading(false);
    }
  };

  if (!showSearch) {
    return (
      <div className="space-y-4">
        <div className="text-center py-8 mx-auto max-w-2xl">
          <button
            onClick={onShowSearch}
            className="inline-flex items-center gap-2 px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors shadow-sm"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
            {t('scanNewMenuOrSearch')}
          </button>
        </div>

        {restaurantInfo && Object.keys(restaurantInfo).length > 0 && (
          <div className="text-center text-gray-600">
            <span className="inline-flex items-center gap-2">
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                  d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
              </svg>
              <span>
                {restaurantInfo.restaurantName}
                {restaurantInfo.city && `, ${restaurantInfo.city}`}
              </span>
            </span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="text-center py-12">
      <h1 className="text-4xl font-bold text-gray-900 mb-4">
        {t('discoverWines')}
      </h1>
      <p className="text-xl text-gray-600 mb-8">
        {t('scanOrSearch')}
      </p>

      <div className="max-w-xl mx-auto space-y-6">
        {/* Scan Button */}
        <button
          onClick={() => fileInputRef.current?.click()}
          disabled={isLoading}
          className="w-full bg-purple-600 text-white py-4 px-8 rounded-lg hover:bg-purple-700 transition duration-200 flex items-center justify-center gap-3 text-lg"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
              d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
              d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
          {isLoading ? t('processing') : t('scanWineMenu')}
        </button>

        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          accept="image/*"
          className="hidden"
        />

        {/* Divider */}
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-200"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-gray-50 text-gray-500">{t('or')}</span>
          </div>
        </div>

        {/* Search Form */}
        <form onSubmit={handleSearch} className="flex gap-2">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={t('searchWine')}
            className="flex-1 p-3 border rounded-lg focus:ring-2 focus:ring-purple-500"
            disabled={isLoading}
          />
          <button
            type="submit"
            disabled={isLoading || !searchQuery.trim()}
            className="px-6 py-3 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition duration-200 disabled:opacity-50"
          >
            {t('search')}
          </button>
        </form>

        {/* App Store Button */}
        <div className="pt-4">
          <a 
            href={process.env.REACT_APP_APP_STORE_URL || 'https://apps.apple.com/ca/app/glugou-wine-recommendations/id6740251395'}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block app-store-download-button"
            onClick={trackAppDownloadClick}
          >
            <img 
              src="/downloadappstore.png" 
              alt="Download on App Store" 
              className="h-12 mx-auto hover:opacity-90 transition-opacity"
            />
          </a>
          <p className="text-sm text-gray-600 mt-2">
            {t('getAppForBetterExperience')}
          </p>
        </div>
      </div>

      {showRestaurantModal && (
        <RestaurantSelectionModal
          onSelect={handleRestaurantSelect}
          onClose={() => {
            setShowRestaurantModal(false);
            if (pendingImageData) {
              processImage(pendingImageData, null);
            }
          }}
        />
      )}
    </div>
  );
}

export default SearchSection;