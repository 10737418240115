import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import LanguageSelector from './LanguageSelector';
import { useNavigate } from 'react-router-dom';

function MobileMenu({ isOpen, onClose, onNavigate, currentPage }) {
  const { t } = useLanguage();
  const navigate = useNavigate();

  const menuItems = [
    { id: 'home', label: t('home') },
    { id: 'favorites', label: t('favorites') },
    { id: 'preferences', label: t('preferences') },
    { id: 'menuHistory', label: t('menuHistory') }
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50">
      <div className="fixed inset-0 bg-black opacity-25" onClick={onClose}></div>
      <div className="fixed inset-y-0 right-0 max-w-xs w-full bg-purple-900 text-white">
        <div className="flex justify-end p-4">
          <button onClick={onClose} className="text-white">✕</button>
        </div>
        <div className="px-2 pt-2 pb-3 space-y-1">
          {menuItems.map((item) => (
            <button
              key={item.id}
              onClick={() => item.action ? item.action() : onNavigate(item.id)}
              className={`w-full flex items-center gap-2 px-3 py-2 rounded-md text-sm font-medium ${
                currentPage === item.id ? 'bg-purple-800' : 'hover:bg-purple-700'
              }`}
            >
              {item.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MobileMenu; 