import React, { useState, useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { loadGoogleMapsApi } from '../utils/googleMaps';

const RestaurantSelectionModal = ({ onSelect, onClose }) => {
  const { t } = useLanguage();
  const [restaurantName, setRestaurantName] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [location, setLocation] = useState('');
  const [placesLoaded, setPlacesLoaded] = useState(false);
  const [loadError, setLoadError] = useState(null);

  useEffect(() => {
    let autocomplete = null;
    let googleInstance = null;
    
    const initializePlaces = async () => {
      try {
        const google = await loadGoogleMapsApi();
        googleInstance = google;
        
        // Check if the API loaded successfully
        if (!google?.maps?.places) {
          throw new Error('Google Maps Places API not available');
        }

        const input = document.getElementById('restaurant-search-processing');
        if (!input) return;

        autocomplete = new google.maps.places.Autocomplete(input, {
          types: ['restaurant'],
          fields: ['name', 'formatted_address', 'place_id']
        });

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place.name) {
            setRestaurantName(place.name);
            setLocation(place.formatted_address || '');
            setSearchInput(place.name);
          }
        });

        setPlacesLoaded(true);
      } catch (error) {
        console.error('Error loading Google Maps:', error);
        setLoadError(error.message);
      }
    };

    initializePlaces();

    // Cleanup
    return () => {
      if (autocomplete && googleInstance?.maps?.event) {
        googleInstance.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, []);

  const handleManualInput = (e) => {
    const value = e.target.value;
    setRestaurantName(value);
    setSearchInput(value);
  };

  const handleConfirm = () => {
    onSelect({
      restaurantName,
      location
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <h2 className="text-xl font-bold mb-4">{t('selectRestaurant')}</h2>
        <p className="text-gray-600 text-sm mb-4">
          {t('optionalRestaurantSelection')}
        </p>
        
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('searchRestaurant')}
            </label>
            <input
              id="restaurant-search-processing"
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-purple-500"
              placeholder={t('searchRestaurantPlaceholder')}
            />
            {loadError && (
              <p className="text-sm text-gray-500 mt-1">
                {t('manualEntryFallback')}
              </p>
            )}
          </div>

          {restaurantName && (
            <div className="bg-gray-50 p-3 rounded-lg">
              <div className="font-medium">{restaurantName}</div>
              {location && (
                <div className="text-sm text-gray-600 mt-1">{location}</div>
              )}
            </div>
          )}
        </div>

        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            {t('skipAndContinue')}
          </button>
          <button
            onClick={handleConfirm}
            disabled={!restaurantName}
            className={`px-4 py-2 bg-purple-600 text-white rounded-lg
              ${!restaurantName ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-700'}
              transition-colors`}
          >
            {t('confirm')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RestaurantSelectionModal; 