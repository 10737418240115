import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { Link } from 'react-router-dom';

function Footer() {
  const { t } = useLanguage();
  
  return (
    <footer className="bg-gray-100">
      <div className="container mx-auto px-4 py-6">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mt-4 md:mt-0">
            <Link 
              to="/contact" 
              className="text-purple-600 hover:text-purple-800 text-sm font-medium"
            >
              {t('contact')}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer; 