import React, { useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { useAuth } from '../contexts/AuthContext';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { countries } from '../utils/countries';
import { trackRegistrationComplete } from '../utils/metaTracking';

function Register({ onClose, onBackToLogin }) {
  const { t, language } = useLanguage();
  const { signup, loginWithGoogle } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 6) errors.push(t('passwordTooShort'));
    if (!/\d/.test(password)) errors.push(t('passwordNeedsNumber'));
    if (!/[A-Z]/.test(password)) errors.push(t('passwordNeedsUppercase'));
    if (!/[a-z]/.test(password)) errors.push(t('passwordNeedsLowercase'));
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Validate required fields
    if (!firstName.trim() || !lastName.trim() || !country.trim()) {
      setError(t('requiredField'));
      return;
    }

    // Validate password
    const passwordErrors = validatePassword(password);
    if (passwordErrors.length > 0) {
      setError(passwordErrors.join('\n'));
      return;
    }

    if (password !== confirmPassword) {
      setError(t('passwordsDoNotMatch'));
      return;
    }

    try {
      setLoading(true);
      const { user } = await signup(email, password);
      
      await Promise.all([
        setDoc(doc(db, 'users', user.uid), {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          country: country,
          countryName: countries.find(c => c.code === country)?.name || '',
          email: email.toLowerCase(),
          createdAt: new Date().toISOString()
        }),
        trackRegistrationComplete({
          email: email.toLowerCase(),
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          country: country
        })
      ]);

      // Add welcome email call
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/api/send-welcome-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email.toLowerCase(),
            firstName: firstName.trim(),
            language
          })
        });
        console.log('Welcome email sent successfully');
      } catch (emailError) {
        console.error('Error sending welcome email:', emailError);
        // Don't fail registration if email fails
      }

      // Show success toast
      setShowToast(true);
      
      // Hide toast after 3 seconds and close modals
      setTimeout(() => {
        setShowToast(false);
        onClose();
        onBackToLogin();
      }, 3000);
      
    } catch (error) {
      console.error('Registration error:', error);
      switch (error.code) {
        case 'auth/email-already-in-use':
          setError(t('emailAlreadyInUse'));
          break;
        case 'auth/invalid-email':
          setError(t('invalidEmail'));
          break;
        case 'auth/operation-not-allowed':
          setError('Registration is currently disabled');
          break;
        case 'auth/weak-password':
          setError(t('passwordTooShort'));
          break;
        default:
          setError(t('registrationError'));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Toast notification */}
      {showToast && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-[60] bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded shadow-lg">
          <p className="text-center text-sm font-medium">
            {t('registrationComplete')}
          </p>
        </div>
      )}

      <div 
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
        onClick={handleOverlayClick}
      >
        <div className="w-full max-w-md bg-white rounded-lg shadow p-6 relative">
          <button
            onClick={handleClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
            aria-label="Close"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          <h2 className="text-2xl text-black text-center mb-4">
            Sign up
          </h2>

          {error && (
            <div className="mb-4 text-red-600 text-sm text-center whitespace-pre-line">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder={t('firstName')}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 text-black placeholder-gray-500"
                required
              />
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder={t('lastName')}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 text-black placeholder-gray-500"
                required
              />
            </div>

            <select
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 bg-white text-gray-500 placeholder-gray-500"
              required
            >
              <option value="" disabled>{t('country')}</option>
              {countries.map(({ code, name }) => (
                <option key={code} value={code} className="text-black">
                  {name}
                </option>
              ))}
            </select>

            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('email')}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 text-black placeholder-gray-500"
              required
            />

            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t('password')}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 text-black placeholder-gray-500"
              required
            />

            <div className="text-sm text-gray-600 space-y-1 px-1">
              <p className={`flex items-center gap-2 ${password.length >= 6 ? 'text-green-600' : ''}`}>
                <span className={`text-xs ${password.length >= 6 ? 'text-green-600' : 'text-gray-400'}`}>
                  {password.length >= 6 ? '✓' : '○'}
                </span>
                {t('passwordTooShort')}
              </p>
              <p className={`flex items-center gap-2 ${/\d/.test(password) ? 'text-green-600' : ''}`}>
                <span className={`text-xs ${/\d/.test(password) ? 'text-green-600' : 'text-gray-400'}`}>
                  {/\d/.test(password) ? '✓' : '○'}
                </span>
                {t('passwordNeedsNumber')}
              </p>
              <p className={`flex items-center gap-2 ${/[A-Z]/.test(password) ? 'text-green-600' : ''}`}>
                <span className={`text-xs ${/[A-Z]/.test(password) ? 'text-green-600' : 'text-gray-400'}`}>
                  {/[A-Z]/.test(password) ? '✓' : '○'}
                </span>
                {t('passwordNeedsUppercase')}
              </p>
              <p className={`flex items-center gap-2 ${/[a-z]/.test(password) ? 'text-green-600' : ''}`}>
                <span className={`text-xs ${/[a-z]/.test(password) ? 'text-green-600' : 'text-gray-400'}`}>
                  {/[a-z]/.test(password) ? '✓' : '○'}
                </span>
                {t('passwordNeedsLowercase')}
              </p>
            </div>

            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder={t('confirmPassword')}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 text-black placeholder-gray-500"
              required
            />

            <button
              type="submit"
              disabled={loading}
              className="w-full py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
            >
              {loading ? t('loading') : t('signUp')}
            </button>
          </form>

          <div className="mt-4 text-center text-sm">
            <span className="text-gray-600">{t('alreadyHaveAccount')}</span>{' '}
            <button
              onClick={onBackToLogin}
              className="text-purple-600 hover:text-purple-700"
            >
              {t('login')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register; 