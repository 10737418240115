import React, { useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { useAuth } from '../contexts/AuthContext';
import Register from './Register';

function Login({ onClose }) {
  const { t } = useLanguage();
  const { login, loginWithGoogle } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      setError('');
      await loginWithGoogle();
      // Don't call handleClose here - it will be handled by AuthContext after redirect
    } catch (error) {
      console.error('Failed to start Google sign-in:', error);
      setError(t('loginError'));
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      setLoading(true);
      await login(email, password);
      handleClose();
    } catch (error) {
      console.error('Login error:', error);
      switch (error.code) {
        case 'auth/invalid-email':
          setError(t('invalidEmail'));
          break;
        case 'auth/user-not-found':
          setError(t('userNotFound'));
          break;
        case 'auth/wrong-password':
          setError(t('wrongPassword'));
          break;
        case 'auth/too-many-requests':
          setError(t('tooManyAttempts'));
          break;
        default:
          setError(t('loginError'));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSignUpClick = () => {
    setShowRegister(true);
  };

  if (showRegister) {
    return <Register 
      onClose={() => {
        setShowRegister(false);
        handleClose();
      }} 
      onBackToLogin={() => setShowRegister(false)} 
    />;
  }

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
      onClick={handleOverlayClick}
    >
      <div className="w-full max-w-sm bg-white rounded-lg shadow p-6 relative">
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
          aria-label="Close"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <h2 className="text-2xl text-black text-center mb-4">
          Login
        </h2>

        {error && (
          <div className="mb-4 text-red-600 text-sm text-center">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t('email')}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 text-black placeholder-gray-500"
            required
          />

          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t('password')}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 text-black placeholder-gray-500"
            required
          />

          <button
            type="submit"
            disabled={loading}
            className="w-full py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
          >
            {t('login')}
          </button>
        </form>

        <div className="my-4 text-center text-sm text-gray-500">
          {t('orContinueWith')}
        </div>

        <button
          onClick={handleGoogleSignIn}
          disabled={loading}
          className="w-full py-2 px-4 border border-gray-300 rounded-lg flex items-center justify-center gap-2 hover:bg-gray-50 transition-colors text-gray-600"
        >
          <img
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
            alt="Google"
            className="w-5 h-5"
          />
          {loading ? t('signingIn') : 'Sign in with Google'}
        </button>

        <div className="mt-4 text-center text-sm">
          <span className="text-gray-600">{t('noAccount')}</span>{' '}
          <button
            onClick={handleSignUpClick}
            className="text-purple-600 hover:text-purple-700"
          >
            {t('signUp')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login; 