import React, { createContext, useContext, useState } from 'react';

const LanguageContext = createContext();

export const languages = {
  en: 'English',
  fr: 'Français',
  es: 'Español'
};

export const translations = {
  en: {
    // Menu and search related
    winesInMenu: 'Wines in Menu',
    searchError: 'Error searching for wine',
    scanNewMenu: 'Scan a new menu or search for a specific wine',
    hideSearchScan: 'Hide search & scan',
    scanNewMenuOrSearch: "Scan Another Menu or Search for a Wine",
    clickToStartOver: "Click to start a new wine search or menu scan",
    saveMenu: 'Save Menu',
    clearMenu: 'Clear Menu',

    // Authentication related
    login: "Login",
    signUp: "Sign Up",
    signInWithGoogle: "Sign in with Google",
    passwordTooShort: "Password must be at least 6 characters",
    requiredField: "This field is required",
    email: "Email",
    password: "Password",
    confirmPassword: "Confirm Password",
    noAccount: "Don't have an account?",
    alreadyHaveAccount: "Already have an account?",
    registrationError: "Registration failed. Please try again.",
    passwordsDoNotMatch: "Passwords do not match",
    loading: "Loading...",
    logout: 'Logout',
    loginSignUp: "Login / Sign Up",

    // Registration form
    firstName: "First Name",
    lastName: "Last Name",
    country: "Country",
    selectCountry: "Select your country",
    orContinueWith: "or continue with",
    emailAlreadyInUse: "Email Already in Use",
    registrationComplete: "Registration complete. Thank you for signing up!",

    // Navigation menu
    home: 'Home',
    favorites: 'Favorites',
    preferences: 'Preferences',
    menuHistory: 'Menu History',
    selectLanguage: 'Select Language',

    // Main content
    discoverWines: "Discover and remember great wines",
    scanOrSearch: "Scan a wine menu or search for a specific wine",
    scanWineMenu: "Scan Wine Menu",
    searchWine: "Search for a wine...",
    or: "OR",
    search: "Search",

    // Landing Page
    landingHeroTitle: "Your Personal Wine Guide",
    landingHeroSubtitle: "Get instant wine descriptions and personalized recommendations",
    signUpNow: "Sign Up Now",
    earlyAccessPromo: "Early users get lifetime access for free",
    whyChooseGlugou: "Why Choose Glugou",

    // Features
    scanFeatureTitle: "Instant Wine Insights",
    scanFeatureDesc: "Take a photo of any wine menu and get key characteristics",
    preferencesFeatureTitle: "Smart Recommendations",
    preferencesFeatureDesc: "As you mark wines you love (or don't), our recommendations adapt to your unique taste preferences",
    sommelierFeatureTitle: "Sommelier's Best Friend",
    sommelierFeatureDesc: "Show your personalized Wine Preferences card to sommeliers for expert recommendations",
    trackingFeatureTitle: "Digital Wine Journal",
    trackingFeatureDesc: "Keep track of your favorite wines digitally. No cellar needed, just your phone",

    // Status messages
    pleaseLoginToSeeFavorites: "Please log in to see your favorite wines",
    noPreferencesSet: "There are no preferences saved yet.",
    pleaseLoginForPreferences: "Please register or sign in to see any saved preferences",

    // Add to Home Screen
    addToHomeScreen: 'Add to Home Screen',
    addToHomeScreenPrompt: 'To install Glugou on your device:\n\n' +
      '• iOS: Tap the share button (📤) and choose "Add to Home Screen"\n' +
      '• Android: Tap the menu (⋮) and select "Add to Home Screen"',
    gotIt: 'Got it',
    dismiss: 'Dismiss',
    dontShowAgain: 'Don\'t show again',

    // Bottom CTA
    readyToStart: "Ready to Elevate Your Wine Experience?",
    startJourney: "Start discovering wines that match your taste perfectly",
    trustedByWineLovers: "Trusted By Wine Lovers",

    // Preferences page
    noPreferencesSet: "There are no preferences saved yet.",
    pleaseLoginForPreferences: "Please register or sign in to see any saved preferences",
    showToSommelier: "Show this to your sommelier for personalized recommendations",
    preferredCharacteristics: "Preferred Characteristics",
    favoriteRegions: "Favorite Regions",
    preferredVarietals: "Preferred Varietals",
    redWinePreferences: "Red Wine Preferences",
    whiteWinePreferences: "White Wine Preferences",
    sparklingWinePreferences: "Sparkling Wine Preferences",
    orangeWinePreferences: "Orange Wine Preferences",
    roseWinePreferences: "Rosé Wine Preferences",

    // Restaurant Selection Modal
    selectRestaurant: "What restaurant is this from?",
    optionalRestaurantSelection: "Select a restaurant so we can improve our wine knowledge",
    searchRestaurant: "Select the restaurant by name and city",
    searchRestaurantPlaceholder: "Start typing the restaurant's name...",
    skipAndContinue: "Skip and Continue",
    confirm: "Confirm",
    processing: "Processing...",

    // Filter related
    showFilters: "Show Filters",

    // Save wine dialog
    pleaseLoginToSaveWines: "Please login or register to save wines to your favorites",

    // Wine card labels
    type: "Type",
    region: "Region",
    year: "Year",
    varietal: "Varietal",

    // Privacy Policy
    privacyPolicy: 'Privacy Policy',
    informationWeCollect: 'Information We Collect',
    informationWeCollectDesc: 'We collect information that you provide directly to us when using our services.',
    personalInfoItem1: 'Name and contact information when you create an account',
    personalInfoItem2: 'Wine preferences and tasting history',
    personalInfoItem3: 'Device information and usage data',
    personalInfoItem4: 'Location data when you scan wine menus',
    
    howWeUseInformation: 'How We Use Your Information',
    howWeUseInformationDesc: 'We use the collected information for the following purposes:',
    useInfoItem1: 'To provide and improve our wine recommendation service',
    useInfoItem2: 'To personalize your experience and content',
    useInfoItem3: 'To communicate with you about our services',
    useInfoItem4: 'To ensure the security of our platform',
    
    dataSecurity: 'Data Security',
    dataSecurityDesc: 'We implement appropriate security measures to protect your personal information.',
    
    cookies: 'Cookies',
    cookiesDesc: 'We use cookies and similar tracking technologies to enhance your experience on our platform.',
    
    thirdPartyServices: 'Third-Party Services',
    thirdPartyServicesDesc: 'We use the following third-party services to operate our platform:',
    
    yourRights: 'Your Rights',
    yourRightsDesc: 'You have certain rights regarding your personal information:',
    rightsItem1: 'Right to access your personal data',
    rightsItem2: 'Right to correct inaccurate data',
    rightsItem3: 'Right to delete your data',
    rightsItem4: 'Right to withdraw consent',
    
    contact: 'Contact Us',
    contactDesc: 'If you have any questions about this Privacy Policy, please contact us at:',
    
    lastUpdated: 'Last Updated',

    // English
    byCountry: 'By Country',
    byType: 'By Type',

    // Password validation messages
    passwordTooShort: "Password must be at least 6 characters",
    passwordNeedsNumber: "Password must contain at least one number",
    passwordNeedsUppercase: "Password must contain at least one uppercase letter",
    passwordNeedsLowercase: "Password must contain at least one lowercase letter",

    getAppForBetterExperience: 'Get a better experience with our mobile app',
  },

  fr: {
    // Menu and search related
    winesInMenu: 'Vins en Menu',
    searchError: 'Erreur de recherche de vin',
    scanNewMenu: 'Scanner un nouveau menu ou rechercher un vin spécifique',
    hideSearchScan: 'Masquer recherche et scan',
    scanNewMenuOrSearch: "Scanner un Autre Menu ou Rechercher un Vin",
    clickToStartOver: "Cliquez pour commencer une nouvelle recherche ou scan",
    saveMenu: 'Sauvegarder Menu',
    clearMenu: 'Effacer Menu',

    // Authentication related
    login: "Connexion",
    signUp: "S'inscrire",
    signInWithGoogle: "Se connecter avec Google",
    passwordTooShort: "Mot de passe doit comporter au moins 6 caractères",
    requiredField: "Ce champ est requis",
    email: "Email",
    password: "Mot de passe",
    confirmPassword: "Confirmer le mot de passe",
    noAccount: "Vous n'avez pas de compte ?",
    alreadyHaveAccount: "Vous avez déjà un compte ?",
    registrationError: "L'inscription a échoué. Veuillez réessayer.",
    passwordsDoNotMatch: "Les mots de passe ne correspondent pas",
    loading: "Chargement...",
    logout: 'Déconnexion',
    loginSignUp: "Connexion / Inscription",

    // Main content
    discoverWines: "Découvrez et Mémorisez Vos Vins Préférés",
    scanOrSearch: "Scannez une carte des vins ou recherchez un vin spécifique",
    scanWineMenu: "Scanner une carte des vins",
    searchWine: "Rechercher un vin...",
    or: "OU",
    search: "Rechercher",

    // Landing Page
    landingHeroTitle: "Découvrez et Mémorisez Vos Vins Préférés",
    landingHeroSubtitle: "Scannez les cartes des vins, obtenez des recommandations personnalisées et suivez votre parcours œnologique",

    // Features
    scanFeatureTitle: "Scan de Menu Facile",
    scanFeatureDesc: "Scannez instantanément les cartes des vins et obtenez des informations détaillées",

    // Status messages
    pleaseLoginToSeeFavorites: "Veuillez vous connecter pour voir vos vins favoris",
    noPreferencesSet: "Aucune préférence n'a encore été enregistrée.",
    pleaseLoginForPreferences: "Veuillez vous inscrire ou vous connecter pour voir vos préférences enregistrées",

    // Add to Home Screen
    addToHomeScreen: 'Ajouter à l\'écran d\'accueil',
    addToHomeScreenPrompt: 'Pour installer Glugou sur votre appareil :\n\n' +
      '• iOS : Appuyez sur le bouton partager (📤) puis "Sur l\'écran d\'accueil"\n' +
      '• Android : Appuyez sur le menu (⋮) puis "Ajouter à l\'écran d\'accueil"',
    gotIt: 'Compris',
    dismiss: 'Fermer',
    dontShowAgain: 'Ne plus afficher',

    // Registration form
    firstName: "Prénom",
    lastName: "Nom",
    country: "Pays",
    selectCountry: "Sélectionnez votre pays",
    orContinueWith: "ou continuer avec",
    emailAlreadyInUse: "Email Déjà Utilisé",
    registrationComplete: "Inscription terminée. Merci de vous être inscrit !",

    // Navigation menu
    home: 'Accueil',
    favorites: 'Favoris',
    preferences: 'Préférences',
    menuHistory: 'Historique des Menus',
    selectLanguage: 'Choisir la Langue',

    signUpNow: "Inscrivez-vous Maintenant",
    earlyAccessPromo: "Les premiers utilisateurs obtiennent un accès à vie gratuit",
    whyChooseGlugou: "Pourquoi Choisir Glugou",

    // Features
    preferencesFeatureTitle: "Expérience Personnalisée",
    preferencesFeatureDesc: "Recevez des recommandations basées sur vos préférences",
    sommelierFeatureTitle: "Sommelier IA",
    sommelierFeatureDesc: "Recommandations expertes propulsées par l'intelligence artificielle",
    trackingFeatureTitle: "Suivez Vos Favoris",
    trackingFeatureDesc: "Sauvegardez et organisez vos vins préférés",

    // Bottom CTA
    readyToStart: "Prêt à Élever Votre Expérience du Vin ?",
    startJourney: "Commencez à découvrir des vins qui correspondent parfaitement à vos goûts",
    trustedByWineLovers: "Approuvé Par Les Amateurs de Vin",

    // Preferences page
    noPreferencesSet: "Aucune préférence n'a encore été enregistrée.",
    pleaseLoginForPreferences: "Veuillez vous inscrire ou vous connecter pour voir vos préférences enregistrées",
    showToSommelier: "Montrer ce à votre sommelier pour des recommandations personnalisées",
    preferredCharacteristics: "Caractéristiques Préférées",
    favoriteRegions: "Régions Préférées",
    preferredVarietals: "Variétés Préférées",
    redWinePreferences: "Préférences de Vin Rouge",
    whiteWinePreferences: "Préférences de Vin Blanc",
    sparklingWinePreferences: "Préférences de Vin Fizzant",
    orangeWinePreferences: "Préférences de Vin Orange",
    roseWinePreferences: "Préférences de Vin Rosé",

    // Restaurant Selection Modal
    selectRestaurant: "De quel restaurant est-ce ?",
    optionalRestaurantSelection: "Sélectionnez un restaurant pour améliorer notre connaissance des vins",
    searchRestaurant: "Sélectionnez le restaurant par nom et ville",
    searchRestaurantPlaceholder: "Commencez à taper le nom du restaurant...",
    skipAndContinue: "Passer et Continuer",
    confirm: "Confirmer",
    processing: "Traitement...",

    // Filter related
    showFilters: "Afficher les Filtres",

    // Save wine dialog
    pleaseLoginToSaveWines: "Veuillez vous connecter ou vous inscrire pour sauvegarder des vins dans vos favoris",

    // Wine card labels
    type: "Type",
    region: "Région",
    year: "Année",
    varietal: "Cépage",

    // Privacy Policy
    privacyPolicy: 'Politique de Confidentialité',
    informationWeCollect: 'Informations que Nous Collectons',
    informationWeCollectDesc: 'Nous collectons les informations que vous nous fournissez directement lors de l\'utilisation de nos services.',
    personalInfoItem1: 'Nom et coordonnées lors de la création d\'un compte',
    personalInfoItem2: 'Préférences en matière de vin et historique de dégustation',
    personalInfoItem3: 'Informations sur l\'appareil et données d\'utilisation',
    personalInfoItem4: 'Données de localisation lors du scan des cartes des vins',
    
    howWeUseInformation: 'Comment Nous Utilisons Vos Informations',
    howWeUseInformationDesc: 'Nous utilisons les informations collectées aux fins suivantes :',
    useInfoItem1: 'Pour fournir et améliorer notre service de recommandation de vins',
    useInfoItem2: 'Pour personnaliser votre expérience et le contenu',
    useInfoItem3: 'Pour communiquer avec vous concernant nos services',
    useInfoItem4: 'Pour assurer la sécurité de notre plateforme',
    
    dataSecurity: 'Sécurité des Données',
    dataSecurityDesc: 'Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos informations personnelles.',
    
    cookies: 'Cookies',
    cookiesDesc: 'Nous utilisons des cookies et des technologies de suivi similaires pour améliorer votre expérience sur notre plateforme.',
    
    thirdPartyServices: 'Services Tiers',
    thirdPartyServicesDesc: 'Nous utilisons les services tiers suivants pour exploiter notre plateforme :',
    
    yourRights: 'Vos Droits',
    yourRightsDesc: 'Vous disposez de certains droits concernant vos informations personnelles :',
    rightsItem1: 'Droit d\'accès à vos données personnelles',
    rightsItem2: 'Droit de rectification des données inexactes',
    rightsItem3: 'Droit de suppression de vos données',
    rightsItem4: 'Droit de retrait du consentement',
    
    contact: 'Nous Contacter',
    contactDesc: 'Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter à :',
    
    lastUpdated: 'Dernière Mise à Jour',

    // English
    byCountry: 'By Country',
    byType: 'By Type',

    // French
    byCountry: 'Par Pays',
    byType: 'Par Type',

    // Password validation messages
    passwordTooShort: "Le mot de passe doit contenir au moins 6 caractères",
    passwordNeedsNumber: "Le mot de passe doit contenir au moins un chiffre",
    passwordNeedsUppercase: "Le mot de passe doit contenir au moins une majuscule",
    passwordNeedsLowercase: "Le mot de passe doit contenir au moins une minuscule",

    getAppForBetterExperience: 'Profitez d\'une meilleure expérience avec notre app mobile',
  },

  es: {
    // Menu and search related
    winesInMenu: 'Vinos en Menú',
    searchError: 'Error al buscar vino',
    scanNewMenu: 'Escanear un nuevo menú o buscar un vino específico',
    hideSearchScan: 'Ocultar búsqueda y escaneo',
    scanNewMenuOrSearch: "Escanear Otro Menú o Buscar un Vino",
    clickToStartOver: "Haga clic para iniciar una nueva búsqueda o escaneo",
    saveMenu: 'Guardar Menú',
    clearMenu: 'Borrar Menú',

    // Authentication related
    login: "Iniciar sesión",
    signUp: "Registrarse",
    signInWithGoogle: "Iniciar sesión con Google",
    passwordTooShort: "La contraseña debe tener al menos 6 caracteres",
    requiredField: "Este campo es obligatorio",
    email: "Correo electrónico",
    password: "Contraseña",
    confirmPassword: "Confirmar Contraseña",
    noAccount: "¿No tienes una cuenta?",
    alreadyHaveAccount: "¿Ya tienes una cuenta?",
    registrationError: "El registro falló. Por favor, inténtalo de nuevo.",
    passwordsDoNotMatch: "Las contraseñas no coinciden",
    loading: "Cargando...",
    logout: 'Cerrar Sesión',
    loginSignUp: "Iniciar Sesión / Registrarse",

    // Main content
    discoverWines: "Descubre y Recuerda Tus Vinos Favoritos",
    scanOrSearch: "Escanea menús de vinos, obtén recomendaciones personalizadas y sigue tu viaje vinícola",
    scanWineMenu: "Escanear carta de vinos",
    searchWine: "Buscar un vino...",
    or: "O",
    search: "Buscar",

    // Landing Page
    landingHeroTitle: "Descubre y Recuerda Tus Vinos Favoritos",
    landingHeroSubtitle: "Escanea menús de vinos, obtén recomendaciones personalizadas y sigue tu viaje vinícola",

    // Features
    scanFeatureTitle: "Escaneo Fácil de Menús",
    scanFeatureDesc: "Escanea menús de vinos al instante y obtén información detallada",

    // Status messages
    pleaseLoginToSeeFavorites: "Inicia sesión para ver tus vinos favoritos",
    noPreferencesSet: "No hay preferencias guardadas todavía.",
    pleaseLoginForPreferences: "Por favor regístrate o inicia sesión para ver tus preferencias guardadas",

    // Add to Home Screen
    addToHomeScreen: 'Añadir a la pantalla de inicio',
    addToHomeScreenPrompt: 'Para instalar Glugou en su dispositivo:\n\n' +
      '• iOS: Toque el botón compartir (📤) y elija "Añadir a la pantalla de inicio"\n' +
      '• Android: Toque el menú (⋮) y seleccione "Añadir a la pantalla de inicio"',
    gotIt: 'Entendido',
    dismiss: 'Cerrar',
    dontShowAgain: 'No mostrar de nuevo',

    // Registration form
    firstName: "Nombre",
    lastName: "Apellido",
    country: "País",
    selectCountry: "Selecciona tu país",
    orContinueWith: "o continuar con",
    emailAlreadyInUse: "Correo Electrónico Ya en Uso",
    registrationComplete: "Registro completado. ¡Gracias por registrarte!",

    // Navigation menu
    home: 'Inicio',
    favorites: 'Favoritos',
    preferences: 'Preferencias',
    menuHistory: 'Historial de Menús',
    selectLanguage: 'Seleccionar Idioma',

    signUpNow: "Regístrate Ahora",
    earlyAccessPromo: "Los primeros usuarios obtienen acceso gratuito de por vida",
    whyChooseGlugou: "¿Por Qué Elegir Glugou?",

    // Features
    preferencesFeatureTitle: "Experiencia Personalizada",
    preferencesFeatureDesc: "Recibe recomendaciones basadas en tus preferencias",
    sommelierFeatureTitle: "Sommelier IA",
    sommelierFeatureDesc: "Recomendaciones expertas impulsadas por inteligencia artificial",
    trackingFeatureTitle: "Sigue tus Favoritos",
    trackingFeatureDesc: "Guarda y organiza tus vinos favoritos",

    // Bottom CTA
    readyToStart: "¿Listo Para Elevar Tu Experiencia con el Vino?",
    startJourney: "Empieza a descubrir vinos que coinciden perfectamente con tu gusto",
    trustedByWineLovers: "De Confianza Para Los Amantes del Vino",

    // Preferences page
    noPreferencesSet: "No hay preferencias guardadas todavía.",
    pleaseLoginForPreferences: "Por favor regístrate o inicia sesión para ver tus preferencias guardadas",
    showToSommelier: "Muestra esto a tu sommelier para recomendaciones personalizadas",
    preferredCharacteristics: "Características Preferidas",
    favoriteRegions: "Regiones Favoritas",
    preferredVarietals: "Variedades Preferidas",
    redWinePreferences: "Preferencias de Vino Tinto",
    whiteWinePreferences: "Preferencias de Vino Blanco",
    sparklingWinePreferences: "Preferencias de Vino Fizzante",
    orangeWinePreferences: "Preferencias de Vino Naranja",
    roseWinePreferences: "Preferencias de Vino Rosado",

    // Restaurant Selection Modal
    selectRestaurant: "¿De qué restaurante es esto?",
    optionalRestaurantSelection: "Selecciona un restaurante para mejorar nuestro conocimiento del vino",
    searchRestaurant: "Selecciona el restaurante por nombre y ciudad",
    searchRestaurantPlaceholder: "Empieza a escribir el nombre del restaurante...",
    skipAndContinue: "Omitir y Continuar",
    confirm: "Confirmar",
    processing: "Procesando...",

    // Filter related
    showFilters: "Mostrar Filtros",

    // Save wine dialog
    pleaseLoginToSaveWines: "Por favor inicia sesión o regístrate para guardar vinos en tus favoritos",

    // Wine card labels
    type: "Tipo",
    region: "Región",
    year: "Año",
    varietal: "Variedad",

    // Privacy Policy
    privacyPolicy: 'Política de Privacidad',
    informationWeCollect: 'Información que Recopilamos',
    informationWeCollectDesc: 'Recopilamos la información que nos proporciona directamente al utilizar nuestros servicios.',
    personalInfoItem1: 'Nombre e información de contacto al crear una cuenta',
    personalInfoItem2: 'Preferencias de vino e historial de catas',
    personalInfoItem3: 'Información del dispositivo y datos de uso',
    personalInfoItem4: 'Datos de ubicación al escanear menús de vinos',
    
    howWeUseInformation: 'Cómo Utilizamos su Información',
    howWeUseInformationDesc: 'Utilizamos la información recopilada para los siguientes propósitos:',
    useInfoItem1: 'Para proporcionar y mejorar nuestro servicio de recomendación de vinos',
    useInfoItem2: 'Para personalizar su experiencia y contenido',
    useInfoItem3: 'Para comunicarnos con usted sobre nuestros servicios',
    useInfoItem4: 'Para garantizar la seguridad de nuestra plataforma',
    
    dataSecurity: 'Seguridad de Datos',
    dataSecurityDesc: 'Implementamos medidas de seguridad apropiadas para proteger su información personal.',
    
    cookies: 'Cookies',
    cookiesDesc: 'Utilizamos cookies y tecnologías de seguimiento similares para mejorar su experiencia en nuestra plataforma.',
    
    thirdPartyServices: 'Servicios de Terceros',
    thirdPartyServicesDesc: 'Utilizamos los siguientes servicios de terceros para operar nuestra plataforma:',
    
    yourRights: 'Sus Derechos',
    yourRightsDesc: 'Tiene ciertos derechos con respecto a su información personal:',
    rightsItem1: 'Derecho a acceder a sus datos personales',
    rightsItem2: 'Derecho a corregir datos inexactos',
    rightsItem3: 'Derecho a eliminar sus datos',
    rightsItem4: 'Derecho a retirar el consentimiento',
    
    contact: 'Contáctenos',
    contactDesc: 'Si tiene alguna pregunta sobre esta Política de Privacidad, contáctenos en:',
    
    lastUpdated: 'Última Actualización',

    // English
    byCountry: 'By Country',
    byType: 'By Type',

    // Spanish
    byCountry: 'Por País',
    byType: 'Por Tipo',

    // Password validation messages
    passwordTooShort: "La contraseña debe tener al menos 6 caracteres",
    passwordNeedsNumber: "La contraseña debe contener al menos un número",
    passwordNeedsUppercase: "La contraseña debe contener al menos una letra mayúscula",
    passwordNeedsLowercase: "La contraseña debe contener al menos una letra minúscula",

    getAppForBetterExperience: 'Obtén una mejor experiencia con nuestra app móvil',
  }
};

export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState('en');

  const value = {
    language,
    setLanguage,
    t: (key) => translations[language][key] || key
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
} 