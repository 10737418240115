import React from 'react';
import { useLanguage, languages } from '../contexts/LanguageContext';

function LanguageSelector() {
  const { language, setLanguage } = useLanguage();

  return (
    <div className="relative inline-block">
      <select
        value={language}
        onChange={(e) => setLanguage(e.target.value)}
        className="
          appearance-none 
          bg-purple-800 
          text-white 
          border 
          border-purple-700 
          rounded-md 
          py-2 
          pl-3 
          pr-8 
          text-sm 
          focus:outline-none 
          focus:ring-2 
          focus:ring-purple-400 
          hover:bg-purple-700 
          transition-colors
        "
      >
        {Object.entries(languages).map(([code, name]) => (
          <option key={code} value={code} className="bg-purple-800">
            {name}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
        <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </div>
    </div>
  );
}

export default LanguageSelector; 