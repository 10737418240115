import React, { useMemo } from 'react';

const FiltersSidebar = ({ onFilterChange, activeFilters, wines }) => {
  // Extract available options from current wines
  const availableOptions = useMemo(() => {
    const options = {
      types: new Set(),
      regions: new Set(),
      characteristics: new Set()
    };

    Object.values(wines).forEach(wine => {
      if (wine.type) options.types.add(wine.type.toLowerCase());
      if (wine.region) options.regions.add(wine.region);
      if (wine.description) {
        wine.description.split(',').forEach(trait => {
          options.characteristics.add(trait.trim().toLowerCase());
        });
      }
    });

    return {
      types: Array.from(options.types).sort(),
      regions: Array.from(options.regions).sort(),
      characteristics: Array.from(options.characteristics).sort()
    };
  }, [wines]);

  // Don't render the sidebar if there are no filter options
  if (!Object.values(wines).length || 
      (!availableOptions.types.length && 
       !availableOptions.regions.length && 
       !availableOptions.characteristics.length)) {
    return null;
  }

  const handleFilterChange = (filterType, value) => {
    onFilterChange(filterType, value);
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="font-medium mb-2">Type</h3>
        <select 
          className="w-full p-2 border rounded-lg"
          onChange={(e) => handleFilterChange('type', e.target.value)}
          value={activeFilters.type || ''}
        >
          <option value="">All Types</option>
          {availableOptions.types.map(type => (
            <option key={type} value={type}>
              {type.charAt(0).toUpperCase() + type.slice(1)} Wine
            </option>
          ))}
        </select>
      </div>

      <div>
        <h3 className="font-medium mb-2">Characteristics</h3>
        <div className="space-y-2">
          {availableOptions.characteristics.map(trait => (
            <label key={trait} className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox text-red-800 rounded"
                checked={activeFilters.traits?.includes(trait) || false}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  handleFilterChange('traits', 
                    isChecked 
                      ? [...(activeFilters.traits || []), trait]
                      : (activeFilters.traits || []).filter(t => t !== trait)
                  );
                }}
              />
              <span className="ml-2 capitalize">{trait}</span>
            </label>
          ))}
        </div>
      </div>

      <div>
        <h3 className="font-medium mb-2">Region</h3>
        <select 
          className="w-full p-2 border rounded-lg"
          onChange={(e) => handleFilterChange('region', e.target.value)}
          value={activeFilters.region || ''}
        >
          <option value="">All Regions</option>
          {availableOptions.regions.map(region => (
            <option key={region} value={region}>
              {region}
            </option>
          ))}
        </select>
      </div>

      {(activeFilters.type || activeFilters.region || activeFilters.traits?.length > 0) && (
        <button
          onClick={() => {
            handleFilterChange('type', '');
            handleFilterChange('region', '');
            handleFilterChange('traits', []);
          }}
          className="text-sm text-red-600 hover:text-red-800 font-medium"
        >
          Clear All Filters
        </button>
      )}
    </div>
  );
};

export default FiltersSidebar; 