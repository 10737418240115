import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import Header from './Header';
import Footer from './Footer';

function PrivacyPolicy() {
  const { t } = useLanguage();
  const navigate = useNavigate();

  const handleNavigate = (page) => {
    switch (page) {
      case 'home':
        navigate('/');
        break;
      case 'favorites':
        navigate('/favorites');
        break;
      case 'preferences':
        navigate('/preferences');
        break;
      case 'menuHistory':
        navigate('/menu-history');
        break;
      default:
        navigate('/');
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header 
        onNavigate={handleNavigate}
        currentPage="privacy"
      />
      
      <main className="flex-grow bg-gray-50 py-12">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">
            {t('privacyPolicy')}
          </h1>

          <div className="bg-white rounded-lg shadow-sm p-6 space-y-6">
            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                {t('informationWeCollect')}
              </h2>
              <p className="text-gray-600">
                {t('informationWeCollectDesc')}
              </p>
              <ul className="list-disc ml-6 mt-2 text-gray-600">
                <li>{t('personalInfoItem1')}</li>
                <li>{t('personalInfoItem2')}</li>
                <li>{t('personalInfoItem3')}</li>
                <li>{t('personalInfoItem4')}</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                {t('howWeUseInformation')}
              </h2>
              <p className="text-gray-600">
                {t('howWeUseInformationDesc')}
              </p>
              <ul className="list-disc ml-6 mt-2 text-gray-600">
                <li>{t('useInfoItem1')}</li>
                <li>{t('useInfoItem2')}</li>
                <li>{t('useInfoItem3')}</li>
                <li>{t('useInfoItem4')}</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                {t('dataSecurity')}
              </h2>
              <p className="text-gray-600">
                {t('dataSecurityDesc')}
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                {t('cookies')}
              </h2>
              <p className="text-gray-600">
                {t('cookiesDesc')}
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                {t('thirdPartyServices')}
              </h2>
              <p className="text-gray-600">
                {t('thirdPartyServicesDesc')}
              </p>
              <ul className="list-disc ml-6 mt-2 text-gray-600">
                <li>Firebase Authentication</li>
                <li>Google Cloud Services</li>
                <li>Google Analytics</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                {t('yourRights')}
              </h2>
              <p className="text-gray-600">
                {t('yourRightsDesc')}
              </p>
              <ul className="list-disc ml-6 mt-2 text-gray-600">
                <li>{t('rightsItem1')}</li>
                <li>{t('rightsItem2')}</li>
                <li>{t('rightsItem3')}</li>
                <li>{t('rightsItem4')}</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                {t('contact')}
              </h2>
              <p className="text-gray-600">
                {t('contactDesc')}
              </p>
              <p className="text-gray-600 mt-2">
                Email: info@glugou.com
              </p>
            </section>

            <section className="text-sm text-gray-500">
              <p>{t('lastUpdated')}: 2024-02-20</p>
            </section>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default PrivacyPolicy; 