import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { doc, setDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useLanguage } from '../contexts/LanguageContext';
import { enableIndexedDbPersistence } from 'firebase/firestore';

const WineCard = ({ wine, isSearchResult, isRecommended }) => {
  const { t } = useLanguage();
  const { currentUser } = useAuth();
  const [isSaved, setIsSaved] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkStatus = async () => {
      if (!currentUser || !wine?.name) {
        return;
      }

      try {
        const savedRef = doc(db, 'users', currentUser.uid, 'savedWines', wine.name);
        const savedDoc = await getDoc(savedRef);
        setIsSaved(savedDoc.exists());

        const dislikedRef = doc(db, 'users', currentUser.uid, 'dislikedWines', wine.name);
        const dislikedDoc = await getDoc(dislikedRef);
        setIsDisliked(dislikedDoc.exists());
      } catch (error) {
        console.error('Error checking wine status:', error);
        setError('Error checking wine status');
      }
    };

    checkStatus();
  }, [currentUser, wine?.name]);

  const handleSaveWine = async () => {
    if (!currentUser) {
      if (window.confirm(t('pleaseLoginToSaveWines'))) {
        document.querySelector('[data-login-button]')?.click();
      }
      return;
    }

    if (saving) return;

    try {
      setSaving(true);
      setError(null);
      
      const savedRef = doc(db, 'users', currentUser.uid, 'savedWines', wine.name);
      const dislikedRef = doc(db, 'users', currentUser.uid, 'dislikedWines', wine.name);
      
      if (isSaved) {
        await deleteDoc(savedRef);
        setIsSaved(false);
      } else {
        if (isDisliked) {
          await deleteDoc(dislikedRef);
          setIsDisliked(false);
        }
        await setDoc(savedRef, {
          ...wine,
          savedAt: new Date().toISOString(),
          source: isSearchResult ? 'search' : 'menu'
        });
        setIsSaved(true);
      }
    } catch (error) {
      console.error('Error saving wine:', error);
      setError('Error saving wine');
    } finally {
      setSaving(false);
    }
  };

  const handleDislikeWine = async () => {
    if (!currentUser) {
      if (window.confirm(t('pleaseLoginToDislikeWines'))) {
        // Trigger login modal
        document.querySelector('[data-login-button]')?.click();
      }
      return;
    }

    if (saving) return;

    try {
      setSaving(true);
      setError(null);
      
      const savedRef = doc(db, 'users', currentUser.uid, 'savedWines', wine.name);
      const dislikedRef = doc(db, 'users', currentUser.uid, 'dislikedWines', wine.name);
      
      if (isDisliked) {
        await deleteDoc(dislikedRef);
        setIsDisliked(false);
      } else {
        if (isSaved) {
          await deleteDoc(savedRef);
          setIsSaved(false);
        }
        await setDoc(dislikedRef, {
          ...wine,
          dislikedAt: new Date().toISOString(),
          source: isSearchResult ? 'search' : 'menu'
        });
        setIsDisliked(true);
      }
    } catch (error) {
      console.error('Error updating wine status:', error);
      setError('Error updating wine status');
    } finally {
      setSaving(false);
    }
  };

  const getTypeColor = (type) => {
    const colors = {
      red: 'text-red-800',
      white: 'text-yellow-700',
      orange: 'text-orange-600',
      sparkling: 'text-purple-600'
    };
    return colors[type?.toLowerCase()] || 'text-gray-800';
  };

  const getDescriptorColor = (type) => {
    const colors = {
      red: 'bg-red-50 text-red-700 border-red-200',
      white: 'bg-yellow-50 text-yellow-700 border-yellow-200',
      orange: 'bg-orange-50 text-orange-700 border-orange-200',
      sparkling: 'bg-purple-50 text-purple-700 border-purple-200'
    };
    return colors[type?.toLowerCase()] || 'bg-gray-50 text-gray-700 border-gray-200';
  };

  return (
    <div className={`bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 overflow-hidden
      ${isRecommended ? 'ring-2 ring-purple-500 ring-opacity-50' : ''}`}
    >
      {error && (
        <div className="px-4 py-2 bg-red-100 text-red-700 text-sm">
          {error}
        </div>
      )}
      <div className="p-5">
        {isRecommended && (
          <div className="flex items-center gap-1 text-purple-600 text-sm mb-2">
            <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            Recommended for You
          </div>
        )}
        <div className="flex justify-between items-start mb-3">
          <h3 className={`font-bold text-xl ${getTypeColor(wine.type)}`}>
            {wine.name}
          </h3>
          <div className="flex gap-2">
            <button
              onClick={handleSaveWine}
              disabled={saving}
              className={`p-2 rounded-full hover:bg-gray-100 transition-colors ${
                saving ? 'opacity-50' : ''
              }`}
              title={isSaved ? "Remove from favorites" : "Add to favorites"}
            >
              {isSaved ? (
                <FavoriteIcon 
                  className="text-red-500"
                  sx={{ fontSize: 24 }}
                />
              ) : (
                <FavoriteBorderIcon 
                  className="text-gray-400 hover:text-red-500"
                  sx={{ fontSize: 24 }}
                />
              )}
            </button>
            <button
              onClick={handleDislikeWine}
              disabled={saving}
              className={`p-2 rounded-full hover:bg-gray-100 transition-colors ${
                saving ? 'opacity-50' : ''
              }`}
              title={isDisliked ? "Remove dislike" : "Dislike this wine"}
            >
              {isDisliked ? (
                <ThumbDownIcon 
                  className="text-gray-800"
                  sx={{ fontSize: 24 }}
                />
              ) : (
                <ThumbDownOffAltIcon 
                  className="text-gray-400 hover:text-gray-800"
                  sx={{ fontSize: 24 }}
                />
              )}
            </button>
          </div>
        </div>
        
        {wine.description && (
          <div className="mb-4 h-8">
            <div className="flex gap-2 overflow-x-auto whitespace-nowrap hide-scrollbar">
              {wine.description.split(',').map((descriptor, index) => (
                <span 
                  key={index}
                  className={`inline-flex px-3 py-1 rounded-full text-sm border flex-shrink-0 ${getDescriptorColor(wine.type)}`}
                >
                  {descriptor.trim()}
                </span>
              ))}
            </div>
          </div>
        )}

        <div className="space-y-2 text-sm border-t pt-3">
          <div className="grid grid-cols-2 gap-2">
            {wine.year && (
              <div className="flex items-baseline">
                <span className="text-gray-500 w-20">{t('year')}:</span>
                <span className="text-gray-900">{wine.year}</span>
              </div>
            )}
            {wine.type && (
              <div className="flex items-baseline">
                <span className="text-gray-500 w-20">{t('type')}:</span>
                <span className="text-gray-900 capitalize">{wine.type}</span>
              </div>
            )}
            {wine.varietal && (
              <div className="flex items-baseline">
                <span className="text-gray-500 w-20">{t('varietal')}:</span>
                <span className="text-gray-900">{wine.varietal}</span>
              </div>
            )}
            {wine.region && (
              <div className="flex items-baseline">
                <span className="text-gray-500 w-20">{t('region')}:</span>
                <span className="text-gray-900">{wine.region}</span>
              </div>
            )}
            {wine.country && (
              <div className="flex items-baseline">
                <span className="text-gray-500 w-20">{t('country')}:</span>
                <span className="text-gray-900">{wine.country}</span>
              </div>
            )}
          </div>
          {wine.descriptionParagraph && (
            <div className="mt-3 text-gray-600">
              {wine.descriptionParagraph}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WineCard; 