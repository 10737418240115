let isLoading = false;
let isLoaded = false;
const callbacks = [];

export const loadGoogleMapsApi = () => {
  return new Promise((resolve, reject) => {
    // If already loaded, return the google object
    if (window.google?.maps?.places) {
      resolve(window.google);
      return;
    }

    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    if (!apiKey) {
      reject(new Error('Google Maps API key is missing'));
      return;
    }

    if (isLoading) {
      callbacks.push({ resolve, reject });
      return;
    }

    isLoading = true;

    // Create script element
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;

    // Handle script load
    script.onload = () => {
      // Add a small delay to ensure the API is fully initialized
      setTimeout(() => {
        if (window.google?.maps?.places) {
          isLoaded = true;
          isLoading = false;
          callbacks.forEach(callback => callback.resolve(window.google));
          callbacks.length = 0;
          resolve(window.google);
        } else {
          const error = new Error('Google Maps Places API failed to initialize');
          isLoading = false;
          callbacks.forEach(callback => callback.reject(error));
          callbacks.length = 0;
          reject(error);
        }
      }, 100);
    };

    // Handle script error
    script.onerror = (error) => {
      isLoading = false;
      const loadError = new Error('Failed to load Google Maps script');
      callbacks.forEach(callback => callback.reject(loadError));
      callbacks.length = 0;
      reject(loadError);
    };

    document.head.appendChild(script);
  });
}; 