import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  browserLocalPersistence, 
  setPersistence,
  GoogleAuthProvider,
  signInWithPopup,
  browserPopupRedirectResolver
} from 'firebase/auth';
import { getFirestore, enableMultiTabIndexedDbPersistence } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAZ-Zt3vhgE2bBuQ1OZpqJxVMh3T6X74SI",
  authDomain: "glugou.com",
  projectId: "glugou",
  storageBucket: "glugou.firebasestorage.app",
  messagingSenderId: "830996090101",
  appId: "1:830996090101:web:081e0a2c724f2c654289bc",
  measurementId: "G-Y78JC5HCX4"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Configure Google Auth Provider
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: 'select_account',
  access_type: 'offline',
  include_granted_scopes: true
});

// Update the signInWithGoogle function
export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(
      auth, 
      googleProvider, 
      browserPopupRedirectResolver
    );
    return result;
  } catch (error) {
    if (error.code === 'auth/popup-closed-by-user') {
      // Handle user closing popup
      console.log('Sign-in popup closed by user');
    }
    throw error;
  }
};

// Set auth persistence to local
setPersistence(auth, browserLocalPersistence)
  .catch((error) => {
    console.error('Error setting auth persistence:', error);
  });

// Enable multi-tab persistence for Firestore
const db = getFirestore(app);
try {
  enableMultiTabIndexedDbPersistence(db).catch((err) => {
    if (err.code === 'failed-precondition') {
      console.log('Persistence disabled: multiple tabs open');
    } else if (err.code === 'unimplemented') {
      console.log('Persistence not supported by browser');
    }
  });
} catch (err) {
  console.log('Error enabling persistence:', err);
}

export { auth, db };
export default app; 